import React, { useEffect } from 'react'
import Row from '../../containers/layout/Row'
import Column from '../../containers/layout/Column'
import './BookingPage.css'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useParams } from 'react-router'
import isEmpty from 'lodash.isempty'
import { setRestaurant } from '../restaurant/services/actions'
import {
  booking_page_path,
  booking_success_path,
} from '../../config/pages_paths'
import 'react-datepicker/dist/react-datepicker.css'
import { setActiveModal } from '../../store/actions/feedbackActions'
import { getUser } from '../../store/actions/usersActions'
import TermsOfUse from '../../components/book/components/TermsOfUse'
import BookingOptions from '../../components/book/BookingOptions'
import { bookTable } from './services/actions'
import { createFormattedDateString } from '../../config/timeDate'
import useUserDetails from '../../hooks/useUserDetails'

function BookingPage() {
  const {
    restaurant: { restaurant },
    restaurants: { restaurants },
    feedback: { responseMessage },
    booking: { reservationId },
    user: { user }
  } = useSelector(state => state)

  const { restaurantId } = useParams()
  const dispatch = useDispatch()
  const history = useHistory()
  // const { hasAllFields, onMissingFields } = useUserDetails({
  //   requireAllFields: true
  // })

  useEffect(() => {
    if (!isEmpty(restaurants)) {
      const restaurantFromId =
        restaurants.find(val => +val.restaurant_id === +restaurantId) || {}
      dispatch(setRestaurant(restaurantFromId))
    }
  }, [dispatch, restaurantId, restaurants, restaurant])

  const onBookClickHandler = (
    date,
    sizeOfParty = 1,
    specialRequest,
    emailMarketingOpt,
    tableType
  ) => {
    const formattedDate = createFormattedDateString(date)
    dispatch(
      bookTable(
        {
          date: formattedDate,
          sizeOfParty,
          specialRequest,
          emailMarketingOpt,
          tableType
        },
        restaurantId
      )
    )
  }

  useEffect(() => {
    if (responseMessage && reservationId)
      history.push(
        booking_success_path
        .replace(':restaurantId', restaurantId)
        .replace(':reservationId', reservationId)
      )
  }, [responseMessage, reservationId, history])

  // useEffect(() => {
  //   dispatch(getUser())
  //   return () => {
  //     dispatch(setActiveModal(''))
  //   }
  // }, [dispatch])

  return (
    <Row className="mb-4">
      <Column className="text-center booking-left-column" md={6} lg={4}>
        <img
          alt="restaurant"
          className="booking-restaurant-image"
          src={restaurant.image}
        />
        <div className="booking-restaurant-heading mx-auto">{restaurant.name}</div>
        <div className="booking-restaurant-address mx-auto">
          {restaurant.address}
        </div>
      </Column>
      <Column className="booking-settings-column" md={6} lg={4}>
        <BookingOptions
          restaurantId={restaurantId}
          timezone={restaurant.timezone}
          // hasAllFields={hasAllFields}
          onBookClickHandler={
            // hasAllFields ? 
            onBookClickHandler 
            // : onMissingFields
          }
          user={user}
        />
        <TermsOfUse />
      </Column>
    </Row>
  )
}

export default BookingPage
