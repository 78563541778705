import isEmpty from 'lodash.isempty'
import React from 'react'
import { useHistory } from 'react-router'
import { reservations_path } from '../../config/pages_paths'
import { useDispatch, useSelector } from 'react-redux'
import { setInitReservationType } from '../../pages/reservations/services/actions'
import reservationTypeEnum from '../../enums/reservationTypeEnum'

const FloatingButtonWrapper = ({ component: Component }) => {
    const dispatch = useDispatch()
    const history = useHistory()

    const {
        booking: { reservations: { ongoing } },
    } = useSelector(state => state)

    const handleOrderNavigation = () => {
        dispatch(setInitReservationType(reservationTypeEnum.ongoing))
        history.push(reservations_path)
    }

    // console.log(!isEmpty(ongoing))

    return (
        <>
            {typeof Component === 'function' ? (
                <Component />
            ) : Component ? (
                React.cloneElement(Component, {
                    // You can pass additional props if needed
                })
            ) : null}

            {!isEmpty(ongoing) ? (
                <div className="restaurant-actions-container" style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                    <button className="btn btn-primary big-round-btn-auto-width" onClick={handleOrderNavigation}>
                        <span>Back to Your Order</span>
                    </button>
                </div>
            ) : null
            }
        </>
    )
}

export default FloatingButtonWrapper