import {
  SET_RESTAURANT,
  SET_MENU,
  SET_FULL_MENU,
  SET_CURRENT_POSITION,
  SET_IS_PAYMENT_REQUIRED,
  SET_MODIFIERS_FOR_MENU_ITEM,
  SET_SELECTED_MODIFIERS_FOR_ORDER_ITEM,
  SET_RESTAURANT_BY_ID
} from '../../../config/actionTypes'
import axios from 'axios'
import { setErrors } from '../../../store/actions/errorsActions'
import { home_path } from '../../../config/pages_paths'
import {setLoader, setToastMessage} from '../../../store/actions/feedbackActions' 

export const getMenu = restaurantId => async dispatch => {
  try {
    dispatch(setLoader(true))
    const result = await axios.get('/api/restaurants/top-menu/' + restaurantId)
    dispatch(setMenu(result.data.topMenuItems))
    dispatch(setLoader(false))
  } catch (err) {
    dispatch(setLoader(false))
    dispatch(setErrors(err, () => getMenu(restaurantId)))
  }
}

export const setRestaurant = payload => dispatch => {
  dispatch({ type: SET_RESTAURANT, payload })
}

export const setRestaurantById = payload => dispatch => {
  dispatch({ type: SET_RESTAURANT_BY_ID, payload })
}

export const setMenu = payload => dispatch => {
  dispatch({ type: SET_MENU, payload })
}

export const setFullMenu = payload => dispatch => {
  dispatch({ type: SET_FULL_MENU, payload })
}

export const getFullMenu = (
  restaurantId,
  reservationId,
  isNow,
  history
) => async dispatch => {
  try {
    dispatch(setLoader(true))
    
    let query = reservationId ? '?reservationId=' + reservationId : (isNow === 'now' ? '?now=true' : '')
    const result = await axios.get(
      '/api/restaurants/menu/' + restaurantId + query
    )
    dispatch(setFullMenu(result.data.menu))
    dispatch(setLoader(false))
  } catch (err) {
    dispatch(setLoader(false))
    if ((err.response || {}).status === 400) {
      if (err.response && err.response.data && err.response.data.short_message) {
        dispatch(setToastMessage({
          message: err.response.data.short_message,
          severity: "error",
        }))
      }
      history && history.push(home_path)
    }

    dispatch(setErrors(err, () => getFullMenu(restaurantId, reservationId, isNow, history)))
  }
}

export const setCurrentPosition = payload => dispatch => {
  dispatch({ type: SET_CURRENT_POSITION, payload })
}

export const getIsPaymentRequired = (restaurantId) => async dispatch => {
  try {
    dispatch(setLoader(true))
    const response = await axios.get('/api/restaurants/is-payment-required', {params: {restaurantId}})
    dispatch(setLoader(false))
    dispatch(setIsPaymentRequired(response.data?.is_payment_required))
  } catch(err) {
    dispatch(setLoader(false))
    dispatch(setErrors(err))
  }
}

export const setIsPaymentRequired = payload => dispatch =>{
  dispatch({type: SET_IS_PAYMENT_REQUIRED, payload})
}

export const getModifiersForMenuItem = (menuItemId, menuSectionId, restaurantId) => async dispatch => {
  return await new Promise((resolve, _) => {
    try {
      axios.get('/api/restaurants/modifiers', {params: {menuItemId, menuSectionId, restaurantId}}).then(({ data }) => {
        dispatch(setModifiersForMenuItem(data))
        return resolve(null, data)
      }).catch((error) => {
        return resolve(error)
      })
    } catch (err) {
      dispatch(setErrors(err, () => getModifiersForMenuItem(menuItemId, menuSectionId, restaurantId)))
    }
  })
}

export const setModifiersForMenuItem = payload => dispatch => {
  dispatch({type: SET_MODIFIERS_FOR_MENU_ITEM, payload})
}

export const getSelectedModifiersForOrderItem = (orderItemId, restaurantId) => async dispatch => {
  return await new Promise((resolve, _) => {
    try {
      axios.get('/api/orders/selected-modifiers', {params: {orderItemId, restaurantId}}).then(({ data }) => {
        dispatch(setSelectedModifiersForOrderItem(data))
        return resolve(null, data)
      }).catch((error) => {
        return resolve(error)
      })
    } catch (err) {
      dispatch(setErrors(err, () => getSelectedModifiersForOrderItem(orderItemId, restaurantId)))
    }
  })
}

export const setSelectedModifiersForOrderItem = payload => dispatch => {
  dispatch({type: SET_SELECTED_MODIFIERS_FOR_ORDER_ITEM, payload})
}
