import {
  SET_SHOW_NAVIGATION,
  SET_MENU_ACTIVE_NAME,
  SET_MENU_ACTIVE_SUB_NAME,
  SET_REDIRECT_TO_HOME,
} from '../../../config/actionTypes'

export const showNavigation = payload => dispatch => {
  dispatch({ type: SET_SHOW_NAVIGATION, payload })
}

export const setMenuActiveName = payload => dispatch => {
  dispatch({ type: SET_MENU_ACTIVE_NAME, payload })
}

export const setMenuActiveSubName = payload => dispatch => {
  dispatch({ type: SET_MENU_ACTIVE_SUB_NAME, payload })
}

export const setRedirectToHome = payload => dispatch => {
  dispatch({ type: SET_REDIRECT_TO_HOME, payload })
}