import { Drawer } from '@mui/material'
import { makeStyles } from '@mui/styles'
import classnames from 'classnames'
import isEmpty from 'lodash.isempty'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useParams } from 'react-router'
import { ExpandLessIcon200, ExpandMoreIcon200 } from '../../assets/svgs-google/GoogleIcons'
import CustomButtonMenu from '../../components/common/CustomButtonMenu'
import { setMenuActiveName, setMenuActiveSubName } from '../../components/navigation/services/actions'
import SideMenuBadge from '../../components/orders/SideMenuBadge'
import MenuItemDetailsModal from '../../components/restaurant/MenuItemDetailsModal'
import SideMenu from '../../components/sideMenu/SideMenu'
import SideMenuMenu from "../../components/sideMenu/SideMenuMenu"
import { full_menu_path } from '../../config/pages_paths'
import Column from '../../containers/layout/Column'
import Row from '../../containers/layout/Row'
import { getRestaurant } from '../../store/actions/restaurantsActions'
import { MenuItemsList } from '../order/OrderPage'
import './FullMenuPage.css'
import { getFullMenu, setFullMenu } from './services/actions'
import { CustomDrawer } from '../../components/common/previewComponents'



function SideMenuItem({
  orders,
  type,
  activeSubType,
  onChange,
  activeIndex,
  onChangeType,
  index,
  isMenuOpened,
  sub_types,
  menu_id,
  showHeadings,
  sectionItemsQuantity,
  menuItemsQuantity,
  isFullMenuPage
}) {
  let isActiveMenu = +activeIndex === index
  let showMenu =
    isActiveMenu && isMenuOpened
      ? {}
      : isActiveMenu && !isMenuOpened
        ? { display: 'none', height: '0', maxHeight: '0' }
        : !isActiveMenu
          ? { display: 'none', height: '0', maxHeight: '0' }
          : {}
    // let menuItemsQuantity2 = sectionItemsQuantity

  return (
    <>
      {showHeadings ? <div
        onClick={
          () => onChangeType(index, type, isActiveMenu)}
        className={classnames('full-menu-type-heading', { first: index === 0 })}
      >
        <div className='menu-heading-container'>
        <h2 className={classnames({
          active: isActiveMenu && isMenuOpened
        })}
        >{type}</h2>
          </div>

        <div className='side-menu-container'>

        {!!(!isMenuOpened || !isActiveMenu ) && !isFullMenuPage &&
        menuItemsQuantity?.map((menu,idx) => menu_id === menu.menuId &&
          <div key={menu_id  || idx} className="side-menu-badge-container">
              <SideMenuBadge quantity={menu.quantity} />
          </div>
        )}
          {isActiveMenu && isMenuOpened ? ExpandLessIcon200(32, '#020083 ') : ExpandMoreIcon200(32, '#D5D5D5')}
          </div>

      </div> : null}
      <div
        className={classnames('full-menu-wrapper', {
          active: isActiveMenu && isMenuOpened
        })}
        style={showMenu}
      >
        {isActiveMenu && isMenuOpened &&
        <div className='side-menu-item-separator'/>}

        <SideMenu
          orders={orders}
          activeSubType={activeSubType}
          listItems={sub_types.filter(val => val.menu_id === menu_id)}
          onClick={onChange}
          sectionItemsQuantity={sectionItemsQuantity}
          onMenu
        />
      </div>
          <div className="side-menu-item-separator"/>
    </>
  )
}

export function SideMenuDesktop({ menuTypes = [], subTypes = [], ...props }) {
  const {
    orders: { lastRoundOrders, sectionItemsQuantity, menuItemsQuantity },
  } = useSelector(state => state)

  return menuTypes.map((type, index) => {
    return (
      <SideMenuItem
        orders={lastRoundOrders}
        key={type.menu_id}
        menu_id={type.menu_id}
        isMenuOpened={props.isMenuOpened}
        activeIndex={props.activeType}
        type={type.label}
        index={index}
        activeSubType={props.activeSubType}
        sub_types={subTypes}
        onChangeType={props.onActiveTypeChangeHandler}
        onChange={props.onSubTypeChangeHandler}
        showHeadings={showHeadings(menuTypes)}
        sectionItemsQuantity={sectionItemsQuantity}
        menuItemsQuantity={menuItemsQuantity}
        isFullMenuPage={props.isFullMenuPage}
      />
    )
  })
}

const showHeadings = (menuTypes) => {
  return menuTypes.length > 1
}

export function SideMenuMobile({
  menuTypes = [],
  subTypes,
  activeSubType,
  anchor,
  toggleDrawer,
  isMenuOpened,
  ...props
}) {

  // const useStyles = makeStyles((theme) => ({
  //   drawerPaper: {
  //     minWidth: "80%"
  //   }
  // }))
  // const classes = useStyles()

  const activeMenu = menuTypes[props.activeType] || {}
  const {
    restaurant: { restaurant },
    orders: { lastRoundOrders, menuItemsQuantity, sectionItemsQuantity, },
    navigation: { menuActiveName, menuActiveSubName },
    booking: { currentReservation }
  } = useSelector(state => state)
  const history = useHistory()
  const dispatch = useDispatch()

  const menuSubItems = (
    <div id={'tab-menu-container'}>
      <SideMenu
        orders={lastRoundOrders}
        listItems={subTypes.filter(val => val.menu_id === activeMenu.menu_id)}
        onClick={props.onSubTypeChangeHandler}
        activeSubType={activeSubType}
        menuItemsQuantity={menuItemsQuantity}
        sectionItemsQuantity={sectionItemsQuantity}
        onMenu
        />
    </div>
  )

  useEffect(() => {
    if (isEmpty(restaurant) && currentReservation?.restaurant_id) {
      dispatch(getRestaurant(currentReservation?.restaurant_id, history))
    }
  }, [dispatch, currentReservation?.restaurant_id, history, restaurant])

  const tabMenuItemsContainer = (
    <div className='menu-container'>

      {!isEmpty(restaurant) &&
      <div className="restaurant-logo-wrapper">
        <img src={restaurant.logo ?? restaurant.image} alt="restaurant-logo" />
      </div>
      }

      <div id={'tab-menu-container'}>
        <SideMenuMenu
          orders={lastRoundOrders}
          listItems={menuTypes.map((item, idx) => ({
            id: idx,
            ...item
          }))}
          onClick={props.onActiveTypeChangeHandler}
          activeType={props.activeType}
          menuSubItems={menuSubItems}
          isMenuOpened={isMenuOpened}
          sectionItemsQuantity={sectionItemsQuantity}
          menuItemsQuantity={menuItemsQuantity}
          isFullMenuPage={props.isFullMenuPage}
        />
      </div>
    </div>
  )

  let activeTypeNames = menuTypes?.filter(val => val.menu_id === activeMenu.menu_id)
  let activeSubTypeNames = subTypes?.filter(val => val.menu_id === activeMenu.menu_id)

  let activeMenuLabel = (activeTypeNames[0] || {}).label
  let activeMenuSubLabel = (activeSubTypeNames[0] || {}).label

  useEffect(() => {
    dispatch(setMenuActiveName(activeMenuLabel))
    dispatch(setMenuActiveSubName(activeMenuSubLabel))
  }, [dispatch, activeMenuLabel,activeMenuSubLabel,])

  // if you return from dining on add-order or order/:id route, then reset button value

  return (
    <div className='full-menu-container'>
      {
      menuActiveName && menuActiveSubName &&

      <div className='custom-btn-wrapper'>
        <CustomButtonMenu onClick={toggleDrawer}
          icon
          text={menuActiveName + " / " + menuActiveSubName}
          textClassName="voila-primary-menu-btn-text"
          textStyle={{ paddingLeft: "0.5rem"}}
          />
      </div>

      }
      <CustomDrawer
        anchor="left"
        open={anchor}
        toggleDrawer={toggleDrawer}
        className='full-menu-drawer'
        // classes={{paper: classes.drawerPaper }}
        content={tabMenuItemsContainer}
      />



    </div>

  )
}

function FullMenuPage() {
  const { restaurantId } = useParams()
  const {
    restaurant: { fullMenu, },
    orders: {menuItemsQuantity, sectionItemsQuantity}
  } = useSelector(state => state)
  const history = useHistory()
  const [activeType, setActiveType] = useState('0')
  const [activeSubType, setActiveSubType] = useState('0')
  const [isMenuOpened, setMenuOpened] = useState(true)
  const [menuItems, setMenuItems] = useState({})
  const [isMobile, setIsMobile] = useState(false)
  const [subTypes, setSubTypes] = useState([])
  const [types, setTypes] = useState([])
  const [anchor, setAnchor] = useState(false)
  const [isFullMenuPage, setIsFullMenuPage] = useState(false)
  const dispatch = useDispatch()
  const [showMenuItemDetails, setShowMenuItemDetails] = useState({
    item: {},
    show: false
  })

  useEffect(() => {
    if(history.location.pathname === full_menu_path.replace(":restaurantId", restaurantId)) {
      setIsFullMenuPage(true)
    }
    return () => {
      setIsFullMenuPage(false)
    }
  },[history, isFullMenuPage])

  useEffect(() => {
    if (/Mobi|Android/i.test(navigator.userAgent)) {
      setIsMobile(true)
    }
  }, [])

  useEffect(() => {
    dispatch(getFullMenu(restaurantId))
  }, [dispatch, restaurantId])

  useEffect(() => {
    return () => {
      dispatch(setFullMenu([]))
    }
  }, [dispatch])

  useEffect(() => {
    if (!isEmpty(fullMenu)) {
      let menu = {}
      const types = fullMenu.map((type, index) => {
        type.index = index
        return { index, label: type.label, menu_id: type.menu_id, }
      })
      setTypes(types)
      types.forEach(val => {
        let type = fullMenu.find(el => el.label === val.label)
        menu[val.index] = type
          ? type.subCategories.reduce((acc, cur) => {
            if (acc[cur.sub_type]) {
              acc[cur.sub_type] = [
                ...acc[cur.sub_type],
                ...cur.menuItems.map(el => el)
              ]
            } else acc[cur.sub_type] = cur.menuItems.map(el => el)
            return acc
          }, {})
          : {}
      })

      let subTypes = []
      subTypes = fullMenu.reduce((acc, cur) => {
        cur.subCategories.forEach(el => {
          acc.push({ id: el.sub_type,
            label: el.name,
            menu_id: cur.menu_id,
          })
        })
        return acc
      }, [])

      setSubTypes(subTypes)
      setActiveSubType(subTypes[0].id)

      setMenuItems(menu)

      let menuActiveNameOnLoad = (fullMenu[0] || {}).label
      let menuActiveSubNameOnLoad = (subTypes[0] || {}).label

      dispatch(setMenuActiveName(menuActiveNameOnLoad))
      dispatch(setMenuActiveSubName(menuActiveSubNameOnLoad))

      setAnchor(true)
  }

  }, [fullMenu])



  const toggleDrawer = e => {
    if (e.type === 'keydown' && (e.key === 'Tab' || e.key === 'Shift')) return

    setAnchor(prevState => !prevState)
  }

  const onActiveTypeChangeHandler = (idx, label, isActiveMenu) => {
    setMenuOpened(val => (+idx !== +activeType ? true : !val))
    setActiveType(idx)

    if(+activeType !== idx) {
      setActiveSubType(subTypes.find(st => st.menu_id === types[idx].menu_id).id)
    }
    // when activeSubType has an index of 0 don't run this

    if(!isActiveMenu) {
      dispatch(setMenuActiveName(label))
    }
  }

  const onSubTypeChangeHandler = (idx, label) => {
      setActiveSubType(idx)
      dispatch(setMenuActiveSubName(label))
      setAnchor(prevState => !prevState)
  }

  const onMenuItemClick = id => {
    if ((menuItems[+activeType] || {})[activeSubType]) {
      const item = (menuItems[+activeType] || {})[activeSubType].find(
        item => item.menu_item_id === id
      )
      if (item) {
        setShowMenuItemDetails({ item, show: true })
      }
    }
  }

  return (
    <Row className="full-menu-row reservations-row">
      {showMenuItemDetails.show ? (
        <MenuItemDetailsModal
          show={showMenuItemDetails.show}
          close={() => setShowMenuItemDetails({ item: null, show: false })}
          item={showMenuItemDetails.item}
        />
      ) : null}
      <Column md={'10'} lg={8}>
        <Row>
          <Column md="4" lg="4">
            {isMobile ? (
              <SideMenuMobile
                menuTypes={types}
                isMenuOpened={isMenuOpened}
                activeType={activeType}
                activeSubType={activeSubType}
                subTypes={subTypes}
                menuItems={menuItems}
                onActiveTypeChangeHandler={onActiveTypeChangeHandler}
                onSubTypeChangeHandler={onSubTypeChangeHandler}
                anchor={anchor}
                toggleDrawer={toggleDrawer}
                menuItemsQuantity={menuItemsQuantity}
                sectionItemsQuantity={sectionItemsQuantity}
                isFullMenuPage={isFullMenuPage}
              />
            ) : (
              <SideMenuDesktop
                menuTypes={types}
                isMenuOpened={isMenuOpened}
                activeType={activeType}
                activeSubType={activeSubType}
                menuItems={menuItems}
                subTypes={subTypes}
                onActiveTypeChangeHandler={onActiveTypeChangeHandler}
                onSubTypeChangeHandler={onSubTypeChangeHandler}
                isFullMenuPage={isFullMenuPage}
              />
            )}
          </Column>
          <Column
            className="scrollable-list m-0 p-0"
            md="8"
            lg="8"
          >
            <MenuItemsList
              items={(menuItems[activeType] || {})[activeSubType]}
              onClick={onMenuItemClick}
            />
            <div className="order-next-btn" />
          </Column>
        </Row>
      </Column>
    </Row>
  )
}

export default FullMenuPage
