import classnames from 'classnames'
import React from 'react'
import ArrowDown from '../../assets/arrow-down.svg'
import ArrowUp from '../../assets/arrow-up.svg'
import { ExpandLessIcon200, ExpandMoreIcon200 } from '../../assets/svgs-google/GoogleIcons'
import SideMenuBadge from '../orders/SideMenuBadge'
import './SideMenu.css'


function SideMenuMenu({ listItems = [], onClick, activeType, orders, menuSubItems, isMenuOpened, menuItemsQuantity, isFullMenuPage  }) {
  return (
    <ul className="side-menu">
      {listItems.map((item, idx) => {
        let label = item.label
        let isActiveMenu = +activeType === item.id
        let showMenu =
        isActiveMenu && isMenuOpened
          ? {}
          : isActiveMenu && !isMenuOpened
            ? { display: 'none', height: '0', maxHeight: '0' }
            : !isActiveMenu
              ? { display: 'none', height: '0', maxHeight: '0' }
              : {}

        return (
          <div key={item.id || idx}>

            <li 
              onClick={() => onClick(idx, label, isActiveMenu)}
              className={classnames('side-menu-item')}
            >

              <span
                className={classnames('side-menu-menu', {
                  active: isActiveMenu && isMenuOpened
                })}
              >
                {item.text || item.label}
              </span>
           
                {!!(!isMenuOpened || !isActiveMenu) && !isFullMenuPage &&
                menuItemsQuantity?.map((menu,idx) => item.menu_id === menu.menuId && 
                  <div key={item.menu_id || idx} className="side-menu-badge-container">
                    <SideMenuBadge quantity={menu.quantity} />
                  </div>
                )}

              <div className="side-menu-menu-icon">
                {/* <img
                  alt="arrow"
                  src={isActiveMenu && isMenuOpened ? ArrowUp : ArrowDown}
                /> */}
                {isActiveMenu && isMenuOpened  ? ExpandLessIcon200(32, '#020083 ') : ExpandMoreIcon200(32, '#D5D5D5')}
              </div>


            </li>
              <div className={classnames('full-menu-wrapper', {
                active: isActiveMenu && isMenuOpened
              })}
              style={showMenu}
              >
            {isActiveMenu && isMenuOpened &&  
            <div className='side-menu-item-separator'/>}

            {isActiveMenu && isMenuOpened && menuSubItems}
              </div>

          <div className='side-menu-item-separator'/>
          </div>
        )
      })}
    </ul>
  )
}

export default SideMenuMenu
