import reservationTypeEnum from '../../../enums/reservationTypeEnum';

export const reservation_types = activeType => {
  const items = Object.values(reservationTypeEnum).map(type => {
    return ({
      text: type,
      active: (type === activeType),
    })
  });
  return items;
};
