import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import DateButtons from './dateTime/DateButtons'
import HorizontalTabMenu from './dateTime/HorizontalTabMenu'
import TimeButtons from './dateTime/TimeButtons'
import SizePartyButtons from './SizePartyButtons'
import PoweredByOrderMark from './components/PoweredByOrdermark'
import CustomButton from '../common/CustomButton'
import './BookingOptions.css'
import SeparationLine from '../common/SeparationLine'
import {
  searchFreeTables,
  setBookingData
} from '../../pages/book/services/actions'
import { createFormattedDateString } from '../../config/timeDate'
import {
  setActiveModal,
  setToastMessage
} from '../../store/actions/feedbackActions'
import { login_modal, update_user_modal } from '../../config/modal_paths'
import localStorageHelper from '../../utils/localStorageHelper'
import { BOOKING_DATA } from '../../constants'

const timePeriodsMenuItems = () => {
  const today = new Date().getDay()


  return [
    {
      text: today > 5 || today === 0 ? 'Brunch' : 'Lunch',
      times: [
        { time: '11:00 AM', value: '11:00' },
        { time: '01:00 PM', value: '13:00' },
        { time: '03:00 PM', value: '15:00' }
      ],
      active: true
    },
    {
      text: 'Dinner',
      times: [
        { time: '06:30 PM', value: '18:30' },
        { time: '07:00 PM', value: '19:00' },
        { time: '07:30 PM', value: '19:30' }
      ]
    }
  ]
}

const getFirstTabLabel = date => {
  const day = date.getDay()
  return day === 6 || day === 0 ? 'Brunch' : 'Lunch'
}

function BookingOptions({ restaurantId, hasAllFields, user }) {
  const [activeDateBtn, setActiveDateBtn] = useState(-1)
  const [activeTimeBtn, setActiveTimeBtn] = useState(-1)
  const [sizeOfPartyBtn, setSizeOfPartyBtn] = useState(-1)
  const [sizeOfParty, setSizeOfParty] = useState(null)
  const [chooseTimeMenu, setChooseTimeMenu] = useState(timePeriodsMenuItems())
  const [activeDate, setActiveDate] = useState(null)


  const dispatch = useDispatch()
  const [timesAvailable, setTimesAvailable] = useState(
    timePeriodsMenuItems()[0].times
  )

  const onDatePickerChange = date => {
    setActiveDateBtn(2)
    setActiveDate(() => new Date(new Date(date).setSeconds(0, 0)))
  }

  const onTodayClick = () => {
    setActiveDateBtn(0)
    setActiveDate(val =>
      val
        ? new Date(new Date(val).setDate(new Date().getDate()))
        : new Date(new Date().setDate(new Date().getDate()))
    )
  }

  const onTomorrowClick = () => {
    setActiveDateBtn(1)
    setActiveDate(val =>
      val
        ? new Date(new Date(val).setDate(new Date().getDate() + 1))
        : new Date(new Date().setDate(new Date().getDate() + 1))
    )
  }

  const onTimeChange = (time, activeIdx) => {
    setActiveTimeBtn(activeIdx)
    let hours = parseInt(time.split(':')[0]),
      minutes = parseInt(time.split(':')[1])

    const newDate = activeDate
      ? new Date(new Date(new Date(activeDate)).setHours(hours, minutes, 0, 0))
      : new Date(new Date(new Date()).setHours(hours, minutes, 0, 0))

    setActiveDate(new Date(new Date(newDate).setHours(hours, minutes, 0, 0)))

    const currentTime = new Date(newDate)
    const currentHours = currentTime.getHours()
    const currentMinutes = currentTime.getMinutes()

    const updateTimesAvailable = timesAvailable.map((time, idx) => {
      const [hours, minutes] = time.value.split(':')
      time.checked =
        parseInt(hours) === currentHours && parseInt(minutes) === currentMinutes
      return time
    })
    setTimesAvailable(updateTimesAvailable)
  }

  const onSizeOfPartyChange = (value, idx) => {
    setSizeOfPartyBtn(idx)
    setSizeOfParty(value)
  }

  const onChangeChooseTimePeriod = idx => {
    setChooseTimeMenu(val => {
      const update = [...val]
      update.forEach(el => (el.active = false))
      update[idx].active = true
      return update
    })

    const currentTime = activeDate ? new Date(activeDate) : new Date()
    const currentHours = currentTime.getHours()
    const currentMinutes = currentTime.getMinutes()

    const updateTimesAvailable = chooseTimeMenu[idx].times.map((time, idx) => {
      const [hours, minutes] = time.time.split(':')
      time.checked =
        parseInt(hours) === currentHours && parseInt(minutes) === currentMinutes
      return time
    })
    setTimesAvailable(updateTimesAvailable)
  }

  const onSearchTablesClick = (date, sizeOfParty) => {

    if (!date || !sizeOfParty) {
      dispatch(
        setToastMessage({ message: 'Please select all fields', severity: "error", })
      )
      return
    }

    const formattedDate = createFormattedDateString(date.getTime())

    const bookingData = { date: formattedDate, sizeOfParty, restaurantId }


    dispatch(setBookingData(bookingData))
    dispatch(searchFreeTables({ date: formattedDate, sizeOfParty, restaurantId, user }))
  }


  useEffect(() => {
    if (activeDate)
      setChooseTimeMenu(timeMenu =>
        timeMenu.map((menu, idx) => {
          if (idx === 0) {
            menu.text = getFirstTabLabel(activeDate)
          }
          return menu
        })
      )
  }, [activeDate])

  return (
    <React.Fragment>
      <div className="booking-settings-heading text-left align-self-start">
        Day
      </div>
      <DateButtons
        activeDateBtn={activeDateBtn}
        onDatePickerChange={onDatePickerChange}
        onTodayClick={onTodayClick}
        onTomorrowClick={onTomorrowClick}
        activeDate={activeDate}
      />
      <SeparationLine mx={false} />
      <br />
      <div className="booking-settings-heading text-left align-self-start">
        Time
      </div>
      <HorizontalTabMenu
        menuItems={chooseTimeMenu}
        onClick={onChangeChooseTimePeriod}
      />
      <TimeButtons
        activeDate={activeDate}
        timesAvailable={timesAvailable}
        activeTimeBtn={activeTimeBtn}
        onTimeChange={onTimeChange}
      />
      <SeparationLine mx={false} />
      <br />
      <div className="booking-settings-heading text-left align-self-start">
        Size of party
      </div>
      <SizePartyButtons
        sizeOfParty={sizeOfParty}
        activeBtn={sizeOfPartyBtn}
        onClick={onSizeOfPartyChange}
      />
      <PoweredByOrderMark />
      <CustomButton
        wrapperClassName="booking-submit-btn active mx-auto"
        textClassName="booking-submit-btn-text active"
        text="Find a table"
        onClick={
          () => onSearchTablesClick(activeDate, sizeOfParty)
        }
      />
    </React.Fragment>
  )
}

export default BookingOptions
