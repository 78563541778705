import { useCallback, useEffect, useState } from 'react'
import io from 'socket.io-client'
import {
  JOIN, ORDER_CLOSED,
  ORDER_MODIFIED,
  PAY_FOR_OTHERS,
  PAYMENT_MADE,
  SELECTED_FRIENDS,
  REFRESH_EXPIRATION_TOKEN,
  PAYMENT_EXPIRIED,
  ORDER_EXPIRED,
  ORDER_MODIFIED_WITHOUT_RESETING_IS_PAY,
  MENU_ITEM_QUANTITY_CHANGED,
  GUESTS_HAVE_SELECTES_MORE_QUANTITY_THAN_ITEM_HAS,
  MENU_ITEM_IS_OUT_OF_STOCK,
  START_BOOKED_ORDER,
  USER_INFO_CHANGED
} from '../config/socketEvents'
import { BASE_URL } from '../config/keys'

const ENDPOINT = BASE_URL || 'http://localhost:5005' 

function useSocket({
  reservationId,
  shouldConnect,
  onRefresh,
  onOtherPaid,
  onOtherTryingToPay,
  onOrderClosed,
  refreshExpirationToken,
  refreshReservations,
  orderExpired,
  onRefreshWithoutResetingIsPay,
  onMenuItemQuantityChanged,
  onGuestsHaveSelectedMoreQuantityThanItemHas,
  onPaymentExpired,
  onMenuItemIsOutOfStock,
  onStartBookedOrder,
  onUserInfoChanged
}) {
  const [socketInstance, setSocket] = useState(null)
  const [connectSocket, setConnectSocket] = useState(true)

  useEffect(() => {
    const socket = io(ENDPOINT)
    setSocket(socket)

    return () => {
      socket.disconnect()
      setSocket(null)
    }
  }, [])

  useEffect(() => {
    setConnectSocket(false)

    if (shouldConnect) {
      setTimeout(() => {
        setConnectSocket(true)
      }, 2000)
    }
  }, [shouldConnect])

  useEffect(() => {
    if (reservationId && socketInstance && (connectSocket || shouldConnect)) {
      if (!socketInstance.connected) socketInstance.connect()
      socketInstance.emit(JOIN, { reservationId })
    } else if ((!connectSocket || !shouldConnect) && socketInstance) socketInstance.disconnect()
  }, [socketInstance, reservationId, connectSocket, shouldConnect])

  useEffect(() => {
    if (socketInstance) {
      socketInstance.on(ORDER_MODIFIED, () => {
        onRefresh && onRefresh(reservationId)
        refreshExpirationToken && refreshExpirationToken(reservationId)
      })
      socketInstance.on(ORDER_MODIFIED_WITHOUT_RESETING_IS_PAY, () => {
        onRefreshWithoutResetingIsPay && onRefreshWithoutResetingIsPay(reservationId)
      })
      socketInstance.on(PAYMENT_MADE, data => {
        onOtherPaid && onOtherPaid(data)
        onRefreshWithoutResetingIsPay && onRefreshWithoutResetingIsPay(reservationId)
      })
      socketInstance.on(PAYMENT_EXPIRIED, (data) => {
        onPaymentExpired && onPaymentExpired(data)
      })
      socketInstance.on(PAY_FOR_OTHERS, data => {
        onOtherTryingToPay && onOtherTryingToPay(data)
      })
      socketInstance.on(ORDER_CLOSED, () => {
        onOrderClosed && onOrderClosed(reservationId)
        onRefresh && onRefresh(reservationId)
      })
      socketInstance.on(REFRESH_EXPIRATION_TOKEN, () => {
        refreshExpirationToken && refreshExpirationToken(reservationId)
      })
      socketInstance.on(ORDER_EXPIRED, () => {
        refreshReservations && refreshReservations()
        orderExpired && orderExpired()
      })
      socketInstance.on(MENU_ITEM_QUANTITY_CHANGED, data => {
        onMenuItemQuantityChanged && onMenuItemQuantityChanged(data)
        onRefresh && onRefresh(reservationId)
      })
      socketInstance.on(GUESTS_HAVE_SELECTES_MORE_QUANTITY_THAN_ITEM_HAS, (data) => {
        onGuestsHaveSelectedMoreQuantityThanItemHas && onGuestsHaveSelectedMoreQuantityThanItemHas(data)
      })
      socketInstance.on(MENU_ITEM_IS_OUT_OF_STOCK, (itemsNames) => {
        onMenuItemIsOutOfStock && onMenuItemIsOutOfStock(itemsNames)
        onRefresh && onRefresh(reservationId)
      })
      socketInstance.on(START_BOOKED_ORDER, () => {
        onStartBookedOrder && onStartBookedOrder(reservationId)
      })
      socketInstance.on(USER_INFO_CHANGED, (data) => {
        onUserInfoChanged && onUserInfoChanged(data)
      })
    }
  }, [
    socketInstance,
    onRefresh,
    reservationId,
    onOtherPaid,
    onOtherTryingToPay,
    onOrderClosed,
    refreshExpirationToken,
    orderExpired,
    onMenuItemQuantityChanged,
    onMenuItemIsOutOfStock,
    onStartBookedOrder,
    onUserInfoChanged
  ])

  const emitFriendSelected = useCallback(
    (reservationId, userIds, payerId) => {
      if (socketInstance) {
        socketInstance.emit(SELECTED_FRIENDS, {
          reservationId,
          userIds,
          payerId
        })
      }
    },
    [socketInstance]
  )

  return { emitFriendSelected }
}

export default useSocket

