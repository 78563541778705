import {
  guest_selected_items,
  expiration_time_api,
  get_current_reservation_api,
  get_receipt_api,
  get_reservations_api
} from '../../../config/api_endpoints'
import {
  SET_RESERVATIONS,
  SET_RESERVATIONS_MANUALLY,
  SET_CURRENT_RESERVATION,
  SET_INIT_RESERVATION_TYPE,
  SET_REFRESH_CHECKOUT,
  SET_OTHER_TRYING_TO_PAY,
  SET_USER_IDS_SOMEONE_PAYS_FOR,
  SET_SEND_TO_KITCHEN_MODE,
  SET_TIMER_EXPIRATION_TIME,
  SET_DOES_GUESTS_HAVE_SELECTED_ITEMS,
  CLOSE_CURRENT_RESERVATION
} from '../../../config/actionTypes'
import axios from 'axios'
import { setErrors } from '../../../store/actions/errorsActions'
import { setIsForWhomToPayConfirmed, setLoader } from "../../../store/actions/feedbackActions"
import { setIsPay, setIsProceededToCheckoutSelection } from '../../../store/actions/usersActions'

export const getReservations = () => async dispatch => {
  try {
    dispatch(setLoader(true))
    const result = await axios.get(get_reservations_api)
    dispatch(setReservations(result.data.reservations))
    dispatch(setLoader(false))
  } catch (err) {
    dispatch(setLoader(false))
    dispatch(setErrors(err, () => getReservations()))
  }
}

export const getCurrentReservation = (cb) => async dispatch => {
  try {
    dispatch(setLoader(true))
    const result = await axios.get(get_current_reservation_api)
    dispatch(setCurrentReservation(result.data))
    if (cb) cb(result.data)
    dispatch(setIsPay(false))
    dispatch(setIsProceededToCheckoutSelection(false))
    dispatch(setIsForWhomToPayConfirmed(false))
    dispatch(setLoader(false))
  } catch (err) {
    dispatch(setLoader(false))
    dispatch(setErrors(err, () => getCurrentReservation(cb)))
  }
}

export const setRefreshCheckout = payload => dispatch => {
  dispatch({ type: SET_REFRESH_CHECKOUT, payload })
}

export const setReservations = payload => dispatch => {
  dispatch({ type: SET_RESERVATIONS, payload })
}

export const setReservationsManually = payload => dispatch => {
  dispatch({ type: SET_RESERVATIONS_MANUALLY, payload })
}

export const setCurrentReservation = currentReservation => dispatch => {
  dispatch({ type: SET_CURRENT_RESERVATION, payload: currentReservation })
}

export const setInitReservationType = payload => dispatch => {
  dispatch({ type: SET_INIT_RESERVATION_TYPE, payload })
}

export const setOtherTryingToPay = payload => ({
  type: SET_OTHER_TRYING_TO_PAY,
  payload
})

export const setUserIdsSomeonePaysFor = payload => ({
  type: SET_USER_IDS_SOMEONE_PAYS_FOR,
  payload
})

export const setSendToKitchenMode = payload => ({
  type: SET_SEND_TO_KITCHEN_MODE,
  payload
})

export const getReceipt = (reservationId, cb) => async dispatch => {
  try {
    const {
      data,
    } = await axios.get(
        get_receipt_api.replace(':reservationId', reservationId),
        { responseType: "arraybuffer" }
    )
    const blob = new Blob([data], { type: "application/pdf" })
    const url = URL.createObjectURL(blob)
    cb(url)
  } catch (err) {
    console.log('Err', err)
    dispatch(setErrors(err, () => getReceipt(reservationId)))
  }
}

export const editTimerExpirationTime = (reservation_id) => async dispatch => {
  try {
    const expirationTime = await axios.put(expiration_time_api.replace(':reservationId', reservation_id))

    dispatch(setTimerExpirationTime(expirationTime.data.expirationTime))
  } catch (err) {
    dispatch(setErrors(err), () => editTimerExpirationTime(reservation_id))
  }
}

export const getTimerExpirationTime = (reservationId) => async dispatch => {
  try {
    if (reservationId === null || reservationId === undefined || Number.isNaN(reservationId)) return;

    const expirationTime = await axios.get(expiration_time_api.replace(':reservationId', reservationId))

    dispatch(setTimerExpirationTime(expirationTime.data.expirationTime))
  } catch (err) {
    dispatch(setErrors(err), () => getTimerExpirationTime(reservationId))
  }
}

export const setTimerExpirationTime = payload => dispatch => {
  dispatch({type: SET_TIMER_EXPIRATION_TIME, payload})
}

export const checkIfAtLeastOneGuestHasSelectedItem = (reservationId) => async dispatch => {
  try {
    if ((reservationId === null || reservationId === undefined || isNaN(reservationId))) return;

    const checkIfAtLeastOneGuestHasSelectedItem = await axios.get(guest_selected_items.replace(":reservationId", reservationId))

    dispatch(setDoesGuestHaveSelectedItems({reservation: checkIfAtLeastOneGuestHasSelectedItem.data.doesSomeoneHaveSelectedItem, numOfTries: 1}))
  } catch (err) {
    dispatch(setErrors(err), () => checkIfAtLeastOneGuestHasSelectedItem(reservationId))
  }
}

export const setDoesGuestHaveSelectedItems = payload => dispatch => {
  dispatch({type: SET_DOES_GUESTS_HAVE_SELECTED_ITEMS, payload})
}

export const closeCurrentReservation = payload => dispatch => {
  dispatch({type: CLOSE_CURRENT_RESERVATION, payload})
}

export const setReservationType = payload => dispatch => {
  dispatch({type: SET_INIT_RESERVATION_TYPE})
}