// auth
export const login_api = '/api/auth/login'
export const login_with_facebook_api = '/api/auth/login/facebook/device'
export const login_with_google_api = '/api/auth/login/google/device'
export const login_with_apple_api = '/api/auth/login/apple'
export const register_api = '/api/auth/register'
export const register_redirect_api = '/api/auth/register/redirect'
export const register_anonymously_api = '/api/auth/register/anonymous'
export const get_access_token_api = '/api/auth/get-access-token'

// user
export const get_user_api = '/api/users/get-user'
export const update_user_api = '/api/users/update'
export const update_user_password_api = '/api/users/password-update'
export const deactivate_account_api = '/api/users/deactivate-account'
export const reset_password_api = '/api/password-reset'
export const reset_password_redirect_api = '/api/password-reset/redirect'

// restaurant
export const get_restaurants_api = '/api/restaurants/'
export const get_restaurant_api = '/api/restaurants/restaurant/:restaurantId'
export const get_code_list_api = '/api/restaurants/code-list'

// booking
export const book_table_api = '/api/bookings/book/:restaurantId'
export const book_dummmy_table_api = '/api/bookings/book-dummy-table/:restaurantId' // NOT USED
export const create_dummy_reservation_with_order_api = '/api/bookings/book-dummy-table-with-order'
export const start_booked_order_api = '/api/bookings/start'
export const get_reservations_api = '/api/bookings/reservations'
export const get_current_reservation_api = '/api/bookings/current-reservation'
export const get_reservation_details_api = '/api/bookings/reservation-details/:reservationId'
export const guest_selected_items = "/api/bookings/guests-selected-items/:reservationId"
export const expiration_time_api = `/api/bookings/expiration-time/:reservationId`
export const get_invitation_details = '/api/bookings/invite-details/:reservationToken'
export const accept_invitation_api = '/api/bookings/accept-invitation'
export const decline_invitation_api = '/api/bookings/decline-invitation'
export const cancel_reservation_api = '/api/bookings/cancel-table/:reservationId'
export const get_invitation_message_api = '/api/bookings/invite-guest/:reservationId'
export const check_table_validity_api = '/api/bookings/check-table-validity'
export const get_available_times_api = '/api/bookings/free-tables/:restaurantId'
export const has_booked_reservation = '/api/bookings/has-booked-reservation'

// orders
export const get_orders_api = '/api/orders/:reservationId'
export const add_to_order_api = '/api/orders/add-item/:reservationId'
export const order_item_api = '/api/orders/items/'
export const edit_feedback_api = '/api/orders/feedback/:reservationId'
export const get_orders_rounds_api = '/api/orders/rounds/:reservationId'
export const get_orders_no_rounds_api = '/api/orders/no-rounds/:reservationId'
export const get_last_round_orders_api = 'api/orders/last-round/:reservationId'
export const get_checkout_info_api = '/api/orders/checkout/:reservationId'
export const edit_rating_api = '/api/orders/rating/:reservationId'
export const item_info_api = '/api/orders/item-info/:reservationId/:menuItemId'

// payment
export const get_receipt_api = '/api/payments/receipt/:reservationId'
export const complete_orders_api = '/api/payments/pay-orders'
export const send_to_kitchen_api = '/api/payments/send-to-kitchen'
export const get_payment_methods_api = '/api/payments/payment-methods'
export const payment_method_api = '/api/payments/payment-method'
export const validate_apple_pay_session_api = '/api/payments/apple-pay/validate-session'

// email
export const send_client_info_email_api = '/api/email/send-client-info'
export const send_support_email_api = '/api/email/send-support-email'
export const version_config = '/api/version-config'
