import isEmpty from "lodash.isempty"
import React, { useEffect, useState } from 'react'
import 'react-lazy-load-image-component/src/effects/blur.css'
import { useLocation } from 'react-router'
import { full_menu_path, order_page_path } from '../../config/pages_paths'
import Column from '../../containers/layout/Column'
import inventoryStatusEnum from '../../enums/inventoryStatusEnum'
import Image from '../common/Image'
import { useSelector } from "react-redux";
import MenuItemDetailsModal from "./MenuItemDetailsModal"
import MenuItemBadge from '../orders/MenuItemBadge'

export function MenuItem({
  image,
  name,
  about,
  price,
  onClick = () => { },
  quantity,
  inventory_status,
  is_invalid
}) {
  const {
    versionConfig: { empty_modifier_group }
  } = useSelector(state => state)
  
  const location = useLocation()


  const fullMenuPagePreviewPath = location.pathname.split('/')[1] === full_menu_path.split('/')[1]
  const orderPagePath = location.pathname.split('/')[1] === order_page_path.split('/')[1]

  function onClickHandler() {
    if (empty_modifier_group && orderPagePath && is_invalid) {
      return;
    } else if (orderPagePath && inventory_status !== inventoryStatusEnum.OUT_OF_STOCK) {
      onClick()
    } else if (fullMenuPagePreviewPath) { 
      onClick()
    } else { 
      return;
    }
  }

  return (
    <Column justifyContent="center" className="p-0 m-0 mx-auto" col={12}>
      {/* <div onClick={orderPagePath && inventory_status !== inventoryStatusEnum.OUT_OF_STOCK ? onClick : fullMenuPagePreviewPath ? onClick : undefined} className="menu-item-container">
        <div className="menu-item-image">
          {quantity ? <MenuItemBadge number={quantity} /> : null}
          <LazyLoadImage
            alt="menu-item"
            effect="blur"
            src={image || MenuItemPlaceholder}
            placeholderSrc={MenuItemPlaceholder}
          />
        </div>
        <div className="menu-item-right-column">
          <div className='d-flex'>
            <div className="menu-item-name2">{name}</div>
          </div>

          {orderPagePath && inventory_status === inventoryStatusEnum.OUT_OF_STOCK && (
            <p style={{ color: "red", fontSize: "12px" }}>Out of stock</p>
          )}

          <div className="menu-item-about2">
            {about.length > 70 ? about.substr(0, 70) + '...' : about}
          </div>
        </div>
          <div className="menu-item-price2">${price}</div>
      </div> */}
      <div onClick={onClickHandler}
        className='grid-container-1-4'
        style={{ maxWidth: "27rem", justifyContent: "center!important" }}>

        <div className='user-order-item-image-wrapper_'>
          <div className='image-wrapper_'>
            {quantity ? <MenuItemBadge number={quantity} /> : null}

            <Image
              src={image}
              menu
              alt="menu-item"
              className={`user-order-item-image_`}
              pill={'Unavailable'}
            />
            {
            (!empty_modifier_group ? (orderPagePath && inventory_status === inventoryStatusEnum.OUT_OF_STOCK)
              : (orderPagePath && (inventory_status === inventoryStatusEnum.OUT_OF_STOCK || is_invalid))) ?
              <div className='reservation-box-label'>
                Unavailable
              </div> :
              null
            }
          </div>
        </div>
        <div style={{ display: "grid", height: "100%" }}>

          <div className='grid-container-3-1'>

            <div className='user-order-item-details_'>
              <h6>{name}</h6>
              {/* {orderPagePath && inventory_status === inventoryStatusEnum.OUT_OF_STOCK && (
                <p style={{ color: "red", fontSize: "12px" }}>Out of stock</p>
              )} */}


            </div>

            <div className='user-order-item-actions-wrapper_'>
              <div>
                <h6>${price}</h6>
              </div>
            </div>
          </div>
          <div style={{textAlign: "left"}}>
            <small className='user-order-item-description'
              style={{ fontFamily: "Lato-Bold", fontSize: "13px", color: "#877a74", textAlign: "left", }}
            >
              {about}
            </small>
          </div>

        </div>
      </div>
    </Column>
  )
}

function RestaurantMenu({ menu = [] }) {
  const [showMenuItemDetails, setShowMenuItemDetails] = useState({
    item: {},
    show: false
  })
  const [uniqueMenuItems, setUniqueMenuItems] = useState([])

  useEffect(() => {
    if (menu && !isEmpty(menu)) {
      setUniqueMenuItems([
        ...new Map(menu.map(item => [item["menu_item_id"], item])).values()
      ])
    }
    return () => {
      setUniqueMenuItems([])
    }
  }, [menu])

  const onMenuItemDetails = item => {
    setShowMenuItemDetails({ item, show: true })
  }

  // console.log(uniqueMenuItems)
  return (
    <React.Fragment>
      {showMenuItemDetails.show ? (
        <MenuItemDetailsModal
          show={showMenuItemDetails.show}
          close={() => setShowMenuItemDetails({ item: null, show: false })}
          item={showMenuItemDetails.item}
        />
      ) : null}
      {uniqueMenuItems.slice(0, 4).map((item, idx) => {
        return (
          <React.Fragment key={item.menu_item_id}>
            {' '}
            <MenuItem onClick={() => onMenuItemDetails(item)} {...item} />{' '}
            {idx < uniqueMenuItems.length - 1 ? <MenuItemSeparator /> : null}
          </React.Fragment>
        )
      })}
    </React.Fragment>
  )
}

export default RestaurantMenu

function MenuItemSeparator() {
  return <div className="text-center menu-item-separator" />
}
