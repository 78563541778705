import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import LoginButton from '../../../components/auth/login/LoginButton'
import InputField from '../../../components/common/InputField'
import ModalHeading2 from '../../../components/common/ModalHeading2'
import Modal from '../../../components/modal/Modal'
import {
  login_modal,
  register_success_modal
} from '../../../config/modal_paths'
import GoogleLogin from 'react-google-login'
import GoogleImg from '../../../assets/google.svg'
import AppleLogin from '../login/AppleLogin'
import ExternalLoginButton from '../../../components/auth/ExternalLoginButton'
import { isEqual } from 'lodash'
import Column from '../../../containers/layout/Column'
import Row from '../../../containers/layout/Row'
import useForm from '../../../hooks/useForm'
import { setActiveModal } from '../../../store/actions/feedbackActions'
import { setNewUser } from '../../../store/actions/usersActions'
import validateRegister from '../../../validation/register'
import { register } from '../services/actions'
import './RegisterPage.css'

function RegisterPage({ show, close }) {
  const dispatch = useDispatch()
  const {
    user: { newUser },
    feedback: { activeModalMetadata, },
    booking: { invitationToken },
    errors
  } = useSelector(state => state)
  const [formErrors, setFormErrors] = useState({})

  useEffect(() => {
    if (activeModalMetadata?.email || activeModalMetadata?.first_name) {
      setValues(activeModalMetadata)
    }
  }, [])

  const onSubmit = async () => {
    const { errors, isValid } = validateRegister(values)
    if (!isValid) {
      setFormErrors(errors)
      return null
    } else setFormErrors({})

    if (isEqual(values, { ...newUser, onSignUp: true })) {
      close()
      dispatch(setActiveModal(register_success_modal, { ...values, onSignUp: true }))
    }
    else {
      handleRegister()
    }
  }

  const handleRegister = async () => {
    const successRegister = await dispatch(register({ ...values, reservation: invitationToken }))
    if (successRegister) {
      dispatch(setNewUser(values))
      dispatch(setActiveModal(register_success_modal, { ...values, onSignUp: true }))
    }
  }

  const { values, setValues, handleChange, handleSubmit } = useForm(
    {
      email: '',
      first_name: '',
      last_name: '',
      phone: '',
      new_password: '',
      confirm_password: ''
    },
    onSubmit
  )

  const onSignInClickHandler = () => {
    dispatch(setActiveModal(login_modal, { ...activeModalMetadata }))
  }

  return (
    <Modal className="login-modal" show={show} close={close}>
      <ModalHeading2 className="login-header" title1="Log-in" title2="Sign-up" activeTitle={'Sign-up'} onLogin={onSignInClickHandler} onClick={close} />

      <Row>
        <Column className="text-center align-items-center m-4" md={12} lg={12}>
          <div className="register-heading">
            <h3>Sign-up to experience the new dining</h3>
          </div>
        </Column>
        <Column md={12} lg={12}>
          <form onSubmit={handleSubmit}>
            <InputField
              type="email"
              required
              name="email"
              onChange={handleChange}
              label="E-mail address"
              value={values.email}
              error={formErrors.email || errors.email}
            />
            <InputField
              name="new_password"
              type="password"
              onChange={handleChange}
              label="Password"
              value={values.new_password}
              error={formErrors.new_password || errors.new_password}
            />
            <InputField
              style={{ marginBottom: "1.46rem" }}
              name="confirm_password"
              type="password"
              onChange={handleChange}
              label="Confirm Password"
              value={values.confirm_password}
              error={formErrors.confirm_password || errors.confirm_password}
            />
            {/* <div className="external-login-btns mx-auto">
              <span className="login-with" style={{ marginRight: '16px' }}>
                Log-in with
              </span>{' '}
               <FacebookLogin
                appId="847432139083677"
                autoLoad={false}
                callback={responseFacebook}
                disableMobileRedirect={true}
                fields="name,email,picture"
                scope="public_profile,email"
                isMobile={false}
                render={renderProps => (
                  <ExternalLoginButton
                    className="facebook"
                    onClick={renderProps.onClick}
                    img={FacebookImg}
                  />
                )}
              /> 
              <GoogleLogin
                clientId="55700200557-8un2ipb96sqmhpuuf8ilh9riv062st5j.apps.googleusercontent.com"
                render={renderProps => (
                  <ExternalLoginButton
                    onClick={renderProps.onClick}
                    img={GoogleImg}
                  />
                )}
                onSuccess={responseGoogle}
                onFailure={() => onGoogleLoginFailure}
              />
              <AppleLogin />
            </div>
            <button
              style={{ display: 'none' }}
              type="submit"
              onSubmit={handleSubmit}
            /> */}
            <LoginButton onClick={onSubmit} />
          </form>
        </Column>
        <div id="footer" className="login-footer">
          <div className="already-have-account">
            <span>
              Already have an account?{' '}
              <span className=" create-account" onClick={onSignInClickHandler}>
                Log-in
              </span>
            </span>
          </div>
        </div>
      </Row>
    </Modal>
  )
}

export default RegisterPage
