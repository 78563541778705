import React, { useEffect, useState } from 'react'
import CustomButton from '../../common/CustomButton'
import classnames from 'classnames'
import { TimePicker } from '@mui/x-date-pickers'
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DateFnsUtils from '@date-io/date-fns'
import './TimeButtons.css'
import { createTheme } from '@mui/material'
import { ThemeProvider as MuiThemeProvider } from '@mui/material/styles'
import ClockIcon from '../../../assets/clock.svg'
import timeToAMPM from '../../../utils/timeToAMPM'

export const customTheme = createTheme({
  palette: {
    primary: {
      main: '#040082',
      light: '#ffffff',
      dark: '#000000'
    },
    secondary: {
      main: '#d3d3d3'
    }
  }
})
function TimeButtons({
  activeTimeBtn,
  onTimeChange,
  timesAvailable,
  activeDate,
  pickerError
}) {
  const visibleButtons = timesAvailable.slice(0, 3)
  const [showTimePicker, setTimePicker] = useState(false)

  const onOtherTimeChange = time => {
    const hours = time ? new Date(time).getHours() : new Date().getHours()
    const minutes = time ? new Date(time).getMinutes() : new Date().getMinutes()
    time && onTimeChange(`${hours}:${minutes}`, 3)
  }

  const openTimePicker = () => {
    setTimePicker(true)
  }

  const closeTimePicker = () => {
    setTimePicker(false)
  }

  const currentTime = () => {
    if (activeDate) {
      return timeToAMPM(activeDate)
    }
  }

  useEffect(() => {
    if (pickerError) setTimePicker(true)
  }, [pickerError])

  return (
    <div className="booking-btns">
      {visibleButtons.map((button, idx) => {
        return (
          <CustomButton
            disabled={button.disabled}
            key={idx}
            wrapperClassName={classnames('booking-btn', {
              active: button.checked
            })}
            textClassName={classnames('booking-btn-text', {
              active: button.checked
            })}
            onClick={() => onTimeChange(button.value, idx)}
            text={button.time}
          />
        )
      })}
      <MuiThemeProvider theme={customTheme}>
        <LocalizationProvider dateAdapter={DateFnsUtils}>
          <TimePicker
            minutesStep={15}
            ampm={true}
            value={activeDate || new Date(new Date().setHours(11, 0))}
            TextFieldComponent="time-picker-input"
            open={showTimePicker}
            onChange={onOtherTimeChange}
            onClose={closeTimePicker}
          />
        </LocalizationProvider>
      </MuiThemeProvider>
      <CustomButton
        style={{ width: '90px' }}
        wrapperClassName={classnames('booking-btn', {
          active: activeTimeBtn > 2
        })}
        textClassName={classnames('booking-btn-text', {
          active: activeTimeBtn > 2
        })}
        icon={ClockIcon}
        iconClassName={classnames('booking-calendar', {
          active: activeTimeBtn > 2
        })}
        onClick={openTimePicker}
        text={activeTimeBtn > 2 ? currentTime() : 'Other'}
      />
    </div>
  )
}

export default TimeButtons
