import classnames from 'classnames';
import React from 'react';
import SideMenuBadge from '../orders/SideMenuBadge';
import './SideMenu.css';

function SideMenu({ listItems = [], onClick, activeSubType, onMenu, sectionItemsQuantity, isNewMenuEntetiesChanges }) {
  return (
    onMenu ? (
      <ul className="sub-side-menu">
        {
        // isNewMenuEntetiesChanges && listItems.length === 0 ? (
        //   <div style={{color:"red"}}>This menu contains no items</div>
        // ) : 
        (
          listItems.map((item, idx) => {
            return (
              <li
                key={item.id || idx}
                onClick={() => onClick((item.id || idx), item.label)}
                className={classnames('side-menu-sub-item')}
              >
                <div
                  className={classnames('side-menu-line', {
                    active: activeSubType ? activeSubType === item.id : item.active
                  })}
                />
                <span
                  className={classnames({
                    active: activeSubType ? activeSubType === item.id : item.active
                  })}
                >
                  {item.text || item.label}
                </span>
                {sectionItemsQuantity?.map((section, idx) => section.menuSectionId === item.menu_section_id && (
                  <div key={section.menuSectionId || idx} className="side-menu-badge-container">
                    <SideMenuBadge quantity={section.quantity} />
                  </div>
                ))}
              </li>
            );
          })
        )}
      </ul>
    ) : (
      <ul className="sub-side-menu">
        {listItems.map((item, idx) => {
          return (
            <li
              key={item.id || idx}
              onClick={() => onClick((item.id || idx), item.label)}
              className={classnames('side-menu-item off-item')}
            >
              <div
                className={classnames('side-menu-line', {
                  active: activeSubType ? activeSubType === item.id : item.active
                })}
              />
              <span
                className={classnames({
                  active: activeSubType ? activeSubType === item.id : item.active
                })}
              >
                {item.text || item.label}
              </span>
            </li>
          );
        })}
      </ul>
    )
  );
}

export default SideMenu
