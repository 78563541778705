import { Grid } from '@mui/material'
import React, { useState } from 'react'
import codeListEnum from '../../enums/codeListEnum'
import { CustomAutocomplete } from '../common/previewComponents'
import LocationCityIcon from '@mui/icons-material/LocationCity';
import CloseIcon from '@mui/icons-material/Close';
import SearchIcon from '@mui/icons-material/Search';
import DinnerDiningIcon from '@mui/icons-material/DinnerDining';
import LocalDiningIcon from '@mui/icons-material/LocalDining';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import SavedSearchIcon from '@mui/icons-material/SavedSearch';
import { restaurantSortEnum } from '../../enums/restaurantSortEnum';

const RestaurantsFilter = ({ types, cities, menuKeywords, handleChange }) => {
    const menuItems = [
        {
            label: codeListEnum.CITIES,
            data: cities,
            icon: <LocationCityIcon />,
        },
        {
            label: codeListEnum.TYPES,
            data: types,
            icon: <LocalDiningIcon />
        },
        {
            label: codeListEnum.MENU_KEYWORDS,
            data: menuKeywords,
            icon: <DinnerDiningIcon />
        },
    ]

    const sortingItems = [
        {
            label: restaurantSortEnum.recommended,
            data: null,
        },
        {
            label: restaurantSortEnum.rating,
            data: null,
        },
        {
            label: restaurantSortEnum.deliveryPrice,
            data: null,
        },
        {
            label: restaurantSortEnum.deliveryTime,
            data: null,
        },
        {
            label: restaurantSortEnum.distance,
            data: null,
        },


    ]

    const [isAutocompletePopoverOpen, setIsAutocompletePopoverOpen] = useState(false)

    const handleIsAutocompeltePopoverOpen = () => {
        setIsAutocompletePopoverOpen(prevState => !prevState)
    }
    const handleIsAutocompeltePopoverOnOpen = () => {
        setIsAutocompletePopoverOpen(true)
    }
    const handleIsAutocompeltePopoverOnClose = () => {
        setIsAutocompletePopoverOpen(false)
    }

    return (
        <Grid container maxWidth="md" sx={{ display: "flex", justifyContent: "center", alignItems: "center", margin: "auto", marginBottom: "1rem" }}>
            <Grid item xs={12} mx="auto" sx={{
                paddingX: {
                    sm: 3,
                    xs: 0,
                }
            }}>
                <CustomAutocomplete
                    menuItems={menuItems}
                    label={'Filter by Cities, Cuisines, Dishes'}
                    multiple
                    disableCloseOnSelect
                    open={isAutocompletePopoverOpen}
                    onToggle={handleIsAutocompeltePopoverOpen}
                    handleIsAutocompeltePopoverOnOpen={handleIsAutocompeltePopoverOnOpen}
                    handleIsAutocompeltePopoverOnClose={handleIsAutocompeltePopoverOnClose}
                    popupIcon={
                        !isAutocompletePopoverOpen ?
                            <SavedSearchIcon
                                sx={{
                                    color: "#020083",
                                }} fontSize='large' /> :
                            <ArrowDropDownIcon
                                sx={{
                                    color: "#020083",
                                }} fontSize='large'
                            />
                    }
                    onChange={handleChange}
                    mainColor={"#020083"}
                    secondaryColor={"#fff"}
                    closeIcon={<CloseIcon style={{ color: "#fff" }} />}
                    fontFamily={'Cormorant'}
                />
            </Grid>
        </Grid>
    )
}

export default RestaurantsFilter