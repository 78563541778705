import isEmpty from 'lodash.isempty'
import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useParams } from 'react-router'
import { setMenuActiveName, setMenuActiveSubName } from '../../components/navigation/services/actions'
import AddMenuItemModal from '../../components/orders/AddMenuItemModal'
import OrderingNotAvailableModal from '../../components/orders/OrderingNotAvailableModal'
import { MenuItem } from '../../components/restaurant/RestaurantMenu'
import { update_user_name_modal } from '../../config/modal_paths'
import {
  booking_reservation_path,
  order_page_path,
  reservations_path
} from '../../config/pages_paths'
import Column from '../../containers/layout/Column'
import Row from '../../containers/layout/Row'
import useSocket from '../../hooks/useSocket'
import { setActiveModal, setRefreshDataForOpenedMenuItem } from '../../store/actions/feedbackActions'
import { getRestaurants } from "../../store/actions/restaurantsActions"
import { getUser, setIsPay, setUserUpdated } from '../../store/actions/usersActions'
import ReservationPage from '../reservation/ReservationPage'
import ReservationPageWrapper from '../reservation/ReservationPageWrapper'
import {
  checkIfAtLeastOneGuestHasSelectedItem,
  getCurrentReservation, getReservations,
  setCurrentReservation,
  setInitReservationType
} from '../reservations/services/actions'
import { SideMenuDesktop, SideMenuMobile } from '../restaurant/FullMenuPage'
import {
  getFullMenu,
  getIsPaymentRequired,
  getModifiersForMenuItem,
  getSelectedModifiersForOrderItem,
  setFullMenu, setModifiersForMenuItem, setRestaurant, setSelectedModifiersForOrderItem
} from '../restaurant/services/actions'
import './OrderPage.css'
import {
  addItemToOrder,
  createDummyReservationWithOrder, getItemInfo, getLastRoundOrders, getOrders, setOrders
} from './services/actions'
import { getReservationDetails } from '../book/services/actions'
import { orderStatusEnum } from '../../enums/orderStatusEnum'
import reservationTypeEnum from '../../enums/reservationTypeEnum'

export function MenuItemsList({
  items = [],
  onClick = () => { },
  orders = [],
  round = 2,
}) {
  return items.map((el, idx) => {
    let itemInOrder =
      orders.find(val => val.menu_item_id === el.menu_item_id && !val.round) ||
      {}
    return (
      <React.Fragment key={idx + 1}>
        <MenuItem
          key={el.menu_item_id}
          onClick={() => onClick(el.menu_item_id)}
          quantity={itemInOrder.quantity}
          {...el}
        />
        <div key={idx} className="text-center menu-item-separator" />
      </React.Fragment>
    )
  })
}

function OrderPage() {
  const {
    user: { user, isUpdated },
    restaurant: { restaurant, fullMenu, isPaymentRequired },
    restaurants: { restaurants },
    booking: { reservations, currentReservation, doesGuestsHaveSelectedItems, reservationDetails },
    orders: { orders, lastRoundOrders, newOrderDetails },
    navigation: { menuActiveName, redirectToHome },
    feedback: { anchorMenu, refreshDataForOpenedMenuItem },
  } = useSelector(state => state)

  const dispatch = useDispatch()
  const { restaurantId, tableNumber, reservationId } = useParams()
  const [menuItem, setMenuItem] = useState({})
  const [menuItems, setMenuItems] = useState({})
  const [types, setTypes] = useState([])
  const [subTypes, setSubTypes] = useState([])
  const [activeType, setActiveType] = useState('0')
  const [activeSubType, setActiveSubType] = useState('0')
  const [showAddItemModal, setShowItemModal] = useState(false)
  const [disableNextButton, setDisableNextButton] = useState(true)
  const [isMobile, setIsMobile] = useState(false)
  const [isMenuOpened, setMenuOpened] = useState(true)
  const [countRequest, setCountRequest] = useState({
    checkIfAtLeastOneGuestHasSelectedItem: 0,
    getDataForWholeReservation: 0,
  })
  const [selectedReservationId, setSelectedReservationId] = useState({})
  const [connectSocket, setConnectSocket] = useState(true)
  const [reservationStatus, setReservationStatus] = useState('')

  const history = useHistory()
  const [anchor, setAnchor] = useState(false)
  // getCurrentBrowserFingerPrint().then(id => console.log(id))

  // useEffects
  useEffect(() => {
    dispatch(setOrders([]))
  }, [dispatch])

  useEffect(() => {
    if (reservationId)
      dispatch(getReservationDetails(reservationId, history))
  }, [dispatch, reservationId])

  useEffect(() => {
    setReservationStatus(reservationDetails?.reservation?.order_status)
  }, [reservationDetails, setReservationStatus])

  useEffect(() => {
    if (!isEmpty(reservationDetails?.reservation)) {
      dispatch(getFullMenu(reservationDetails.reservation.restaurant_id, reservationDetails.reservation.reservation_id, false, history))
    }
  }, [dispatch, reservationDetails, history])

  useEffect(() => {
    if (reservationId) {
      dispatch(getCurrentReservation())
      dispatch(getOrders(reservationId))
      dispatch(getRestaurants())
      dispatch(getReservations())
    }
  }, [reservationId, dispatch])

  useEffect(() => {
    if (countRequest.getDataForWholeReservation < 2) {
      const isNow = !reservationId ? 'now' : false
      if (!isEmpty(currentReservation)) {
        let restaurant = restaurants.find(val => val.restaurant_id === currentReservation.restaurant_id)

        if (restaurant) {
          dispatch(setRestaurant(restaurant))
        }
      } else if (reservationId) {
        let reservation = reservations ? reservations.upcoming.find(val => val.reservation_id === +reservationId) : {}

        if (reservation) {
          setSelectedReservationId(reservation)
          let restaurant = restaurants.find(val => val.restaurant_id === reservation.restaurant_id)
          if (restaurant) {
            dispatch(setRestaurant(restaurant))
          }
        }
      }

      if (restaurantId || currentReservation?.restaurant_id || newOrderDetails?.restaurantId) {
        // Simpler dispatch added
        // dispatch(getFullMenu(restaurantId || currentReservation?.restaurant_id || newOrderDetails?.restaurantId, currentReservation?.reservation_id || reservationId, isNow, history))
        dispatch(getIsPaymentRequired(restaurantId || currentReservation.restaurant_id || newOrderDetails.restaurantId))
      }
      setCountRequest(prevState => ({ ...prevState, getDataForWholeReservation: countRequest.getDataForWholeReservation + 1 }))
    }
  }, [dispatch, restaurants, currentReservation, newOrderDetails, reservationId, reservations, restaurantId])

  useEffect(() => {
    // Change to get this from reservation
    let menu = {}
    const types = fullMenu.map((type, index) => {
      type.index = index
      return { index, label: type.label, menu_id: type.menu_id }
    })
    setTypes(types)
    types.forEach(val => {
      let type = fullMenu.find(el => el.label === val.label)
      menu[val.index] = type
        ? type.subCategories.reduce((acc, cur) => {
          if (acc[cur.sub_type]) {
            acc[cur.sub_type] = [
              ...acc[cur.sub_type],
              ...cur.menuItems.map(el => el)
            ]
          } else acc[cur.sub_type] = cur.menuItems.map(el => el)
          return acc
        }, {})
        : {}
    })

    let subTypes = fullMenu.reduce((acc, cur) => {
      cur.subCategories.forEach(el => {
        acc.push({
          id: el.sub_type,
          label: el.name,
          menu_id: cur.menu_id,
          menu_section_id: el.section_id
        })
      })
      return acc
    }, [])

    setSubTypes(subTypes)

    if (activeSubType === '0' || activeSubType === undefined) {
      setActiveSubType((subTypes[0] || {}).id)
      setMenuItems(menu)
    }

  }, [fullMenu, activeSubType])

  useEffect(() => {
    if (/Mobi|Android/i.test(navigator.userAgent)) {
      setIsMobile(true)
    }
  }, [])

  useEffect(() => {
    return () => {
      dispatch(setFullMenu([]))
    }
  }, [dispatch])

  useEffect(() => {
    if (!reservationId) {
      if (restaurantId && tableNumber) {
        dispatch(createDummyReservationWithOrder({ restaurantId, tableNumber }, null, history))
      } else {
        dispatch(createDummyReservationWithOrder(newOrderDetails, null, history))
      }
    }
  }, [reservationId, tableNumber, restaurantId])

  useEffect(() => {
    if (reservationId && !isEmpty(currentReservation)) {
      dispatch(getLastRoundOrders(reservationId))
    }
  }, [orders, dispatch, reservationId])

  useEffect(() => {
    if ((currentReservation?.reservation_id === parseInt(reservationId))) {
      dispatch(checkIfAtLeastOneGuestHasSelectedItem(reservationId))
      setConnectSocket(false)

      setTimeout(() => {
        setConnectSocket(true)
      }, 2000)
    }
  }, [dispatch, currentReservation?.reservation_id, reservationId])

  useEffect(() => {
    if (reservationDetails.reservation.order_status === orderStatusEnum.booked) {
      setDisableNextButton(false)
      if (isEmpty(currentReservation && !isEmpty(reservationDetails.reservation))) {
        dispatch(setCurrentReservation(reservationDetails.reservation))
      }
    }
    else {
      if (currentReservation?.reservation_id && doesGuestsHaveSelectedItems.reservation) {
        setDisableNextButton(false)
      } else if (lastRoundOrders && lastRoundOrders.length > 0) {
        setDisableNextButton(false)
      }
      else {
        setDisableNextButton(true)
      }
    }
  }, [dispatch, lastRoundOrders, currentReservation, doesGuestsHaveSelectedItems, reservationDetails])

  useEffect(() => {
    let subTypes = fullMenu.reduce((acc, cur) => {
      cur.subCategories.forEach(el => { acc.push({ id: el.sub_type, label: el.name, menu_id: cur.menu_id }) })
      return acc
    }, [])
    let menuActiveNameOnLoad = (types[0] || {}).label
    let menuActiveSubNameOnLoad = (subTypes[0] || {}).label

    if (!menuActiveName) {
      dispatch(setMenuActiveName(menuActiveNameOnLoad))
      dispatch(setMenuActiveSubName(menuActiveSubNameOnLoad))
    }
    // when adding an item, don't set the names agaian
  }, [fullMenu,])

  useEffect(() => {
    dispatch(getUser())
  }, [dispatch])

  useEffect(() => {
    if (isUpdated) setAnchor(true)
  }, [isUpdated])

  useEffect(() => {
    if (refreshDataForOpenedMenuItem.refresh) {
      dispatch(getItemInfo(refreshDataForOpenedMenuItem.menuItemId, reservationId || currentReservation.reservation_id)).then((data) => {
        let item = !isEmpty(menuItems[+activeType]) ? menuItems[+activeType][activeSubType].find(el => el.menu_item_id === refreshDataForOpenedMenuItem.menuItemId) : {}

        let itemFromOrders = orders.find(val => val.menu_item_id === refreshDataForOpenedMenuItem.menuItemId && !val.round);

        itemFromOrders = {
          ...itemFromOrders,
          inventory_status: data.inventory_status,
          remaining_quantity: data.remaining_quantity,
          reservation_selected_quantity: data?.reservation_selected_quantity || null,
          users: data?.users || []
        }

        setMenuItem({ ...item, ...itemFromOrders })
      })

      dispatch(setRefreshDataForOpenedMenuItem({ menuItemId: refreshDataForOpenedMenuItem.menuItemId, refresh: false }))
    }
  }, [refreshDataForOpenedMenuItem.refresh])

  useEffect(() => {
    if (!isEmpty(user)) {
      if (isEmpty(user?.first_name) && !isUpdated) {
        dispatch(setActiveModal(update_user_name_modal))
      }
      else if (!isEmpty(user?.first_name) && isUpdated) {
        setTimeout(() => {
          dispatch(setUserUpdated(false))
          setAnchor(true)
        }, 500)
      }
      else if (!isUpdated && !isEmpty(user?.first_name)) {
        setTimeout(() => { setAnchor(true) }, 1000)
      }
    }
  }, [dispatch, user, restaurant, isUpdated])


  // eventHandlers
  const onMenuItemClickHandler = async menu_item_id => {
    let item = !isEmpty(menuItems[+activeType]) ? menuItems[+activeType][activeSubType].find(el => el.menu_item_id === menu_item_id) : {}
    if (!isEmpty(item)) {
      let itemFromOrders = orders.find(val => val.menu_item_id === item.menu_item_id && !val.round) || {}

      dispatch(getItemInfo(itemFromOrders.menu_item_id || item.menu_item_id, reservationId)).then((data) => {
        if (itemFromOrders && itemFromOrders.order_item_id) {
          itemFromOrders = {
            ...itemFromOrders,
            inventory_status: data.inventory_status,
            remaining_quantity: data.remaining_quantity,
            reservation_selected_quantity: data?.reservation_selected_quantity || null,
            users: data.users || []
          }
        } else {
          item = {
            ...item,
            inventory_status: data.inventory_status,
            remaining_quantity: data.remaining_quantity,
            reservation_selected_quantity: data?.reservation_selected_quantity || null,
            users: data.users || []
          }
        }

        setMenuItem({ ...item, ...itemFromOrders })
      })

      dispatch(setRefreshDataForOpenedMenuItem({ menuItemId: itemFromOrders.menu_item_id || item.menu_item_id, refresh: false }))

      if (itemFromOrders && itemFromOrders.order_item_id)
        await dispatch(getSelectedModifiersForOrderItem(itemFromOrders.order_item_id, currentReservation.restaurant_id)).then(() => { setShowItemModal(true) })
      else {
        await dispatch(getModifiersForMenuItem(item.menu_item_id, item.section_id, currentReservation.restaurant_id)).then(() => { setShowItemModal(true) })
      }
      dispatch(setIsPay(false))
    }
  }

  const onAddClickHandler = data => {
    dispatch(addItemToOrder(reservationId, data))

    onCloseAddMenuItemHandler()
  }

  const onCloseAddMenuItemHandler = () => {
    setMenuItem({})
    dispatch(setSelectedModifiersForOrderItem([]))
    dispatch(setModifiersForMenuItem([]))
    setShowItemModal(false)
    dispatch(setRefreshDataForOpenedMenuItem({ menuItemId: null, refresh: false }))
  }

  const onNextClick = () => {
    if (reservationDetails?.reservation.order_status === orderStatusEnum.booked) {
      history.push(booking_reservation_path
        .replace(':restaurantId', currentReservation.restaurant_id)
        .replace(":reservationId", reservationId))
    }
    else {
      dispatch(setInitReservationType(reservationTypeEnum.ongoing))
      if (isEmpty(currentReservation) && reservationId) {
        history.push(order_page_path.replace(':reservationId', reservationId))
      }
      else if (currentReservation?.reservation_id === +reservationId) {
        history.push(reservations_path)
      }
      else if (reservationId) {
        history.push(order_page_path.replace(':reservationId', reservationId))
      }
      else history.push(reservations_path)
    }

    dispatch(setIsPay(false))
    // //setting navigation names back to initial
    // console.log(reservationDetails?.reservation.order_status === orderStatusEnum.booked)
    // reservationDetails?.reservation.order_status === 'Booked' &&
    // history.push(booking_reservation_path.replace(":reservationId", reservationId))
  }

  const toggleDrawer = e => {
    // if (e.type === 'keydown' && (e.key === 'Tab' || e.key === 'Shift')) return

    setAnchor(prevState => !prevState)
  }

  const onActiveTypeChangeHandler = (idx, label, isActiveMenu) => {
    setMenuOpened(val => (+idx !== +activeType ? true : !val))
    // console.log(isMenuOpened)
    setActiveType(idx)
    setActiveSubType(subTypes.find(st => st.menu_id === types[idx].menu_id).id)
  }

  // const onMobileActiveTypeChangeHandler = ({ target: { value } }) => {
  //   onActiveTypeChangeHandler(value)
  // }

  const onSubTypeChangeHandler = (idx, label) => {
    setActiveSubType(idx)
    dispatch(setMenuActiveSubName(label))
    setAnchor(prevState => !prevState)
  }
  // eventHandlers

  const onRefresh = useCallback((reservation_id) => {
    if (currentReservation?.reservation_id === parseInt(reservationId)) {
      dispatch(checkIfAtLeastOneGuestHasSelectedItem(reservation_id))

      if (refreshDataForOpenedMenuItem.menuItemId) {
        dispatch(setRefreshDataForOpenedMenuItem({ menuItemId: refreshDataForOpenedMenuItem.menuItemId, refresh: true }))
      }
    }
  }, [dispatch, currentReservation?.reservation_id, reservationId, refreshDataForOpenedMenuItem.menuItemId])

  const onOrderClosed = useCallback((reservationId) => {
    history.push(order_page_path.replace(':reservationId', reservationId))
  }, [history])

  useSocket({
    reservationId: (currentReservation || {}).reservation_id,
    shouldConnect: connectSocket,
    onRefresh,
    onOrderClosed
  })

  return (
    <>
      {reservationDetails?.reservation.is_closed || currentReservation?.is_closed ? (
        <ReservationPageWrapper component={ReservationPage} />
      ) : (

        <Row className="m-4 reservations-row">
          {showAddItemModal ? (
            restaurant.ordermark_availability === 'unavailable' ? (
              <OrderingNotAvailableModal
                show={showAddItemModal}
                close={onCloseAddMenuItemHandler}
              />
            ) : (
              <AddMenuItemModal
                onAddClickHandler={onAddClickHandler}
                show={showAddItemModal}
                close={onCloseAddMenuItemHandler}
                item={menuItem}
              />
            )
          ) : null}
          <Column md={'10'} lg={8}>
            <Row>
              <Column md="4" lg="4">
                {isMobile ? (
                  <SideMenuMobile
                    menuTypes={types}
                    isMenuOpened={isMenuOpened}
                    activeType={activeType}
                    activeSubType={activeSubType}
                    subTypes={subTypes}
                    menuItems={menuItems}
                    onActiveTypeChangeHandler={onActiveTypeChangeHandler}
                    onSubTypeChangeHandler={onSubTypeChangeHandler}
                    anchor={anchor}
                    toggleDrawer={toggleDrawer}
                  />
                ) : (
                  <SideMenuDesktop
                    orders={orders}
                    menuTypes={types}
                    isMenuOpened={isMenuOpened}
                    activeType={activeType}
                    activeSubType={activeSubType}
                    menuItems={menuItems}
                    subTypes={subTypes}
                    onActiveTypeChangeHandler={onActiveTypeChangeHandler}
                    onSubTypeChangeHandler={onSubTypeChangeHandler}
                  />
                )}
              </Column>
              <Column md="8" lg="8">
                <Row>
                  <Column className="scrollable-list orders-list" col={12}>
                    {
                      // isNewMenuEntetiesChanges &&
                      // isEmpty(menuItems[activeType] || {})[activeSubType] ? (
                      //   <div style={{ color: "red" }}>This section contains no items</div>
                      // ) :
                      (
                        <MenuItemsList
                          onClick={onMenuItemClickHandler}
                          orders={orders}
                          items={(menuItems[activeType] || {})[activeSubType]}
                        />
                      )
                    }
                    {!isEmpty((menuItems[activeType] || {})[activeSubType]) ? (
                      <div className="order-next-btn">
                        <button
                          onClick={onNextClick}
                          className="btn btn-primary"
                          disabled={disableNextButton}
                        >
                          Next
                        </button>
                      </div>
                    ) : null}
                  </Column>
                </Row>
              </Column>
            </Row>
          </Column>
        </Row>
      )}
    </>
  )
}

export default OrderPage
