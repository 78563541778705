import classnames from 'classnames'
import React from 'react'

function SideMenuX({ listItems = [], handleActive, activeSubType, orders, reservationType, upcomingBadge, ongoingBadge }) {
    return (
        <ul className='side-menu-x'>
            {listItems?.map((item, idx) => {
                return (
                    <li
                        key={item.id ?? idx}
                        onClick={() => handleActive(item.text)}
                        className='side-menu-wrapper'
                        
                    >
                        <div className='side-menu-x-item off-item'>

                        <div
                            className={classnames('side-menu-x-line', {
                                active: activeSubType ? activeSubType === item.id : item.active
                            })}
                            />


                        <span
                            className={classnames('side-menu-x-text', {
                                active: activeSubType ? activeSubType === item.id : item.active
                            })}
                        >
                            {item.text ?? item.label}

                        </span>
                        </div>
                        {(item.text === listItems[0].text && upcomingBadge > 0) || 
                        (item.text === listItems[1].text && ongoingBadge > 0) 
                        // || (item.text === listItems[2].text && pastBadge > 0) 
                        ?
                            <div className='side-menu-badge-container'>
                                <div className='side-menu-badge'>
                                    {item.text === listItems[0].text && upcomingBadge}
                                    {item.text === listItems[1].text && ongoingBadge}
                                    {/* {item.text === listItems[2].text && pastBadge} */}
                                </div>
                            </div> :
                            null
                        }
                    </li>
                )
            })}
        </ul>
    )
}

export default SideMenuX