import isEmpty from 'lodash.isempty'
import React, { useCallback, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import RestaurantsFilter from '../../components/restaurants/RestaurantsFilter'
import RestaurantsGrid from '../../components/restaurants/RestaurantsGrid'
import Column from '../../containers/layout/Column'
import Row from '../../containers/layout/Row'
import './HomePage.css'
import codeListEnum from '../../enums/codeListEnum'


function HomePage() {
  const {
    restaurants: { restaurants, types, cities, menuKeywords },
    restaurant: { currentPosition },
    booking: { reservations },
    versionConfig: { restaurants_filter: restaurantsFilter }
  } = useSelector(state => state)

  const [selectedFiltersForRestaurants, setSelectedFiltersForRestaurants] = useState([])

  const [selectedRestaurants, setSelectedRestaurants] = useState(restaurants)

  //SHOW ALL RESULTS BASED ON SEARCH FILTERS
  const filterRestaurants = useCallback(() => {
    // Filter the restaurants based on selected types and cities
    const filteredRestaurants = restaurants.filter((restaurant) => {
      const cityFilters = selectedFiltersForRestaurants.filter(
        (filter) => filter.section === codeListEnum.CITIES
      );
  
      const typeFilters = selectedFiltersForRestaurants.filter(
        (filter) => filter.section === codeListEnum.TYPES
      );
  
      const keywordFilters = selectedFiltersForRestaurants.filter(
        (filter) => filter.section === codeListEnum.MENU_KEYWORDS
      );
  
      // Check if any city filter matches the restaurant's city
      if (cityFilters.length > 0 && !cityFilters.some(filter => filter.option.name === restaurant.city)) {
        return false;
      }
  
      // Check if any type filter matches the restaurant's type
      if (typeFilters.length > 0 && !typeFilters.some(filter => restaurant.types.includes(filter.option.name))) {
        return false;
      }
  
      // Check if any keyword filter matches the restaurant's menu_keywords
      if (keywordFilters.length > 0 && !keywordFilters.some(filter => restaurant.menu_keywords.includes(filter.option.name))) {
        return false;
      }
  
      return true; // If any filter conditions pass, include the restaurant
    });
  
    return filteredRestaurants;
  }, [restaurants, selectedFiltersForRestaurants]);

  // SHOW CONCRETE RESULTS BASED ON SEARCH FILTERS
  // const filterRestaurants = useCallback(() => {
  //   // Filter the restaurants based on selected types and cities
  //   const filteredRestaurants = restaurants.filter((restaurant) => {

  //     const cityFilters = selectedFiltersForRestaurants.filter(
  //       (filter) => filter.section === codeListEnum.CITIES
  //     );

  //     if (cityFilters.length > 1) {
  //       // If more than one city is selected, exclude the restaurant for now
  //       return false;
  //     }

  //     if (cityFilters.length === 1 && cityFilters[0].option.name !== restaurant.city) {
  //       // If one city is selected and it doesn't match the restaurant's city, exclude the restaurant
  //       return false;
  //     }

  //     const typeFilters = selectedFiltersForRestaurants.filter(
  //       (filter) => filter.section === codeListEnum.TYPES
  //     );
  //     for (const typeFilter of typeFilters) {
  //       if (!restaurant.types.includes(typeFilter.option.name)) {
  //         return false;
  //       }
  //     }

  //     const keywordFilters = selectedFiltersForRestaurants.filter(
  //       (filter) => filter.section === codeListEnum.MENU_KEYWORDS
  //     );
  //     for (const keywordFilter of keywordFilters) {
  //       if (!restaurant.menu_keywords.includes(keywordFilter.option.name)) {
  //         return false;
  //       }
  //     }

  //     return true; // If all conditions pass, include the restaurant
  //   });
  //   return filteredRestaurants;
  // }, [restaurants, selectedFiltersForRestaurants]);

  useEffect(() => {
    const filteredRestaurants = filterRestaurants();
    setSelectedRestaurants(filteredRestaurants);
  }, [filterRestaurants, setSelectedRestaurants]);

  const handleChange = (event, selectedValues) => {
    setSelectedFiltersForRestaurants(selectedValues);
  };

  return (
    <Row className="home-page-row mx-auto">
      <Column md={12} lg={12} xl={9}>
        {restaurantsFilter === null ? null :
          !restaurantsFilter ?
            <RestaurantsGrid
              restaurants={restaurants}
              currentPosition={currentPosition}
              ongoing={reservations?.ongoing}
            /> :
            (
              !isEmpty(restaurants) ?
                <>
                  <RestaurantsFilter
                    types={types}
                    cities={cities}
                    menuKeywords={menuKeywords}
                    handleChange={handleChange}
                  />
                  <RestaurantsGrid
                    restaurants={isEmpty(selectedFiltersForRestaurants) ? restaurants : selectedRestaurants}
                    currentPosition={currentPosition}
                    ongoing={reservations?.ongoing}
                  />
                  {/* <RestaurantsList 
                  restaurants={isEmpty(selectedFiltersForRestaurants) ? restaurants : selectedRestaurants}
                  /> */}
                </>
                : null
            )
        }
      </Column>
    </Row>
  )
}

export default HomePage
