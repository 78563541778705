import isEmpty from 'lodash.isempty'
import React, { useCallback, useEffect, useState } from 'react'
import 'react-lazy-load-image-component/src/effects/blur.css'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useLocation, useParams } from 'react-router'
import ReservationOrders from '../../components/ordersRounds/ReservationOrders'
import '../../components/reservation/buttons/Buttons.css'
import {
  booking_page_path, order_page_path, reservations_path
} from '../../config/pages_paths'
import Column from '../../containers/layout/Column'
import Row from '../../containers/layout/Row'
import {
  startBookedOrder,
  getInviteMessage,
  getReservationDetails,
  setInviteMessage,
  setReservationId,
} from '../book/services/actions'
import {
  getCurrentReservation,
  getReceipt,
  setInitReservationType
} from '../reservations/services/actions'
import './ReservationPage.css'
import { setActiveModal, setMenuItemQuantityChangedModal, setRefreshDataForOpenedMenuItem } from '../../store/actions/feedbackActions'
import { cancel_reservation_modal } from '../../config/modal_paths'
import ReservationInfoLabel from '../../components/reservation/ReservationInfoLabel'
import ReservationEditButtons from '../../components/reservation/ReservationEditButtons'
import { copyToClipboard } from '../../utils/copyToClipboard'
import ReservationTableNumber from '../../components/reservation/ReservationTableNumber'
import useSocket from '../../hooks/useSocket'
import { getLastRoundOrders, getOrders, getOrdersNoRounds, getOrdersRounds } from '../order/services/actions'
import { orderStatusEnum } from '../../enums/orderStatusEnum'
import errorMessages from '../../components/messages/errorMessages'
import { booking_reservation_path } from '../../config/pages_paths'
import Image from '../../components/common/Image'

function ReservationPage({ reservationIdProp }) {
  const {
    booking: {
      reservationDetails: { reservation, reservationOrders, userPayments, reservationGuests },
      reservations,
      initReservationType,
      switchToInputComponent,
    },
    auth: { user },
    feedback: { responseMessage, connectSocketAfterIdleTimeout, refreshDataForOpenedMenuItem },
    restaurant: { restaurant },
    booking: { inviteMessage },
    versionConfig: { show_booking_flow }
  } = useSelector(state => state)



  const dispatch = useDispatch()
  const { reservationId } = useParams()
  const history = useHistory()
  const location = useLocation()

  const [isInviteCopied, setInviteCopied] = useState(false)
  const [anyOrderPaidByLoggedInUser, setAnyOrderPaidByLoggedInUser] = useState(false)
  const [reservationType, setReservationType] = useState(initReservationType)
  const [receiptURL, setReceiptURL] = useState('')

  useEffect(() => {
    if (reservationId)
      dispatch(getReservationDetails(reservationId, history))
  }, [dispatch, reservationId])

  useEffect(() => {
    // check if any user's order is paid by himself
    // if there is none, hide the receipt button
    // all orders have been paid by other users
    if (reservation.has_receipt) {
      setAnyOrderPaidByLoggedInUser(true)
    }
  }, [reservationOrders, user.id])

  useEffect(() => {
    reservation?.order_status.order_status === orderStatusEnum.booked && reservation?.size_of_party > 1 && dispatch(getInviteMessage(reservationId))
  }, [dispatch, reservation])

  useEffect(() => {
    //Return to this
    const bookingManagePath = location.pathname.split('/')[1] === booking_reservation_path.split('/')[1]

    const userRoleInReservation = reservationGuests.find(guest => guest.user_id === user.id)

    if (bookingManagePath && userRoleInReservation?.user_type === 'HOST' && reservation?.order_status === orderStatusEnum.booked && (reservation?.size_of_party < reservationGuests?.length)) {
      const modalMessage = {
        heading: "Modify the reservation!",
        message: <>
          <span style={{ marginBottom: "1rem" }}>{errorMessages.moreGuestsThanBookedSizeParty}</span>
        </>
      }

      const extraButton = {
        name: "Modify",
        functionality: () => {
          onModifyClickHandler()
        }
      }

      dispatch(setMenuItemQuantityChangedModal({ show: true, message: modalMessage, extraButton: extraButton }))
    }
  }, [dispatch, reservation, reservationGuests, user.id, location.pathname])

  const onInviteClickHandler = () => {
    copyToClipboard(inviteMessage)
    setInviteCopied(true)
  }

  const onCancelInvitationClickHandler = () => {
    dispatch(setReservationId(reservationId))
    dispatch(setActiveModal(cancel_reservation_modal))
  }

  const onModifyClickHandler = () => {
    if (reservation?.reservation_id === +reservationId && reservation?.manage_reservation_url) {
      const modifyWindow = window.open(reservation?.manage_reservation_url, '_blank', 'noopener,noreferrer')
      modifyWindow && modifyWindow.focus()
    }
  }

  const onRebookClick = () => {
    history.push(
      booking_page_path.replace(':restaurantId', reservation.restaurant_id)
    )
  }

  const onReceiptClick = () => {
    dispatch(getReceipt(reservationId))
  }

  const onMenuNavigationClickHandler = () => {
    history.push(order_page_path.replace(':reservationId', reservation.reservation_id))
    // console.log(reservation)
  }

  const onStartOrderClickHandler = (tableNumber) => {
    dispatch(startBookedOrder(tableNumber, reservationId, reservations, history))
  }

  useEffect(() => {
    if (responseMessage) history.push(reservations_path)
  }, [history, responseMessage])

  useEffect(() => {
    return () => {
      setInviteCopied(false)
      dispatch(setInviteMessage(''))
    }
  }, [dispatch])

  useEffect(() => {
    if (!isEmpty(reservations)) {
      !reservations.upcoming.some(
        val => val.reservation_id === reservation.reservation_id
      )
        ? setReservationType(1)
        : reservation.dummy
          ? setReservationType(2)
          : setReservationType(0)
    }
  }, [reservation, reservations, dispatch])

  // useEffect(() => {
  //   return () => {
  //     dispatch(setInitReservationType(reservationType))
  //   }
  // }, [dispatch, reservationType])

  useEffect(() => {
    if (reservationId) {
      dispatch(getReceipt(reservationId, setReceiptURL))
    }
  }, [reservationId, dispatch])

  // const [dateString, time] = reservation.date_string
  //   ? reservation.date_string.split('T')
  //   : []

  const onStartBookedOrder = useCallback((reservationId) => {
    history.push(reservations_path.replace(':reservationId', reservationId))
  }, [history])

  const onRefresh = useCallback(
    reservation_id => {
      if (reservation_id) {
        dispatch(getCurrentReservation())
        dispatch(getOrders(reservation_id))
        dispatch(getOrdersRounds(reservation_id))
        dispatch(getOrdersNoRounds(reservation_id))
        dispatch(getLastRoundOrders(reservation_id))
        dispatch(getReservationDetails(reservation_id, history))

        if (refreshDataForOpenedMenuItem.menuItemId) {
          dispatch(setRefreshDataForOpenedMenuItem({ menuItemId: refreshDataForOpenedMenuItem.menuItemId, refresh: true }))
        }
      }
    },
    [dispatch, refreshDataForOpenedMenuItem.menuItemId]
  )

  useSocket({
    reservationId: reservationId,
    shouldConnect: true && !connectSocketAfterIdleTimeout,
    onStartBookedOrder,
    onRefresh
  })

  const [showInputField, setShowInputField] = useState(false);

  // useEffect(() => {
  //   if (switchToInputComponent?.reservationId === +reservationId) {
  //     setShowInputField(switchToInputComponent?.value);
  //   }
  //   return (
  //     setShowInputField(false)
  //   )
  // }, [dispatch, switchToInputComponent, reservationId]);

  useEffect(() => {
    const matchingReservation = switchToInputComponent.find(item => item?.reservationId === +reservationId);

    if (matchingReservation) {
      setShowInputField(matchingReservation?.value);
    }

    return () => {
      setShowInputField(false);
    };
  }, [switchToInputComponent, reservationId]);



  return (
    <Row>
      <Column className="text-center" md={6} lg={4}>
        <div className='reservation-restaurant-image-container'>
          <Image
            src={reservation.restaurant_image}
            className="restaurant-image"
            restaurant
            style={{ borderRadius: "15px", objectFit: "cover" }}
          />
        </div>
        <div className="booking-restaurant-heading mx-auto">
          {reservation.name}
        </div>
        <div className="booking-restaurant-address mx-auto mb-1">
          {reservation.address}
        </div>
        <div className="booking-reservation-info mb-4">
          <div className="reservation-info-container">
            <div className="reservation-info">
              {reservation.is_walk_in ? reservation.date_string : reservation.booking_date} | {reservation.size_of_party}{' '}person{reservation.size_of_party > 1 ? 's' : ''}
              {reservation.is_closed ? `| Table: ${reservation.table_number}` : ''}
            </div>
          </div>
        </div>
        <Row>
          <Column col={12}>
            {reservation.order_status === orderStatusEnum.booked && (
              <ReservationInfoLabel
                // label="Do not scan the QR code on the table, please enter it manually"
                className="reservation-buttons-container"
                info={
                  <ReservationEditButtons
                    isInviteCopied={isInviteCopied}
                    onInviteClick={onInviteClickHandler}
                    onCancelInvitationClick={onCancelInvitationClickHandler}
                    onMenuNavigationClick={onMenuNavigationClickHandler}
                    onModifyClick={onModifyClickHandler}
                    sizeOfParty={reservation.size_of_party}
                    reservationGuest={reservationGuests.find(guest => guest.user_id === user.id)}
                  />
                }
                table={
                  <ReservationTableNumber
                    reservation={reservation}
                    cb={onStartOrderClickHandler}
                    showInputField={showInputField}
                    hasOpenTableReservationId={!isEmpty(reservation?.opentable_reservation_id)}
                  />
                }
              />
            )}

            {(reservation.order_status === orderStatusEnum.noshow || reservation.order_status === orderStatusEnum.autoClosed) && (
              errorMessages.reservationNoShow
            )}

            {reservation.order_status === orderStatusEnum.cancelled && (
              errorMessages.reservationCancelled
            )}

            {reservation.order_status === orderStatusEnum.orderManuallyClosed && (
              errorMessages.reservationManuallyClosed
            )}

            {reservation.order_status === orderStatusEnum.orderExpired && (
              errorMessages.reservationExpired
            )}

            {(reservation.order_status === orderStatusEnum.orderPaid || reservation.order_status === orderStatusEnum.orderPaidPOS) && (
              <PastReservationButtons
                isInviteCopied={isInviteCopied}
                onRebookClick={onRebookClick}
                onReceiptClick={onReceiptClick}
                receiptURL={receiptURL}
                showReceiptButton={anyOrderPaidByLoggedInUser}
                reservationDate={new Date(+reservation.date).toLocaleString({ hour12: true })}
                restaurantName={restaurant.name}
                isWalkIn={reservation.is_walk_in}
                showBookingFLow={show_booking_flow}
              />
            )}
          </Column>
          <Column col={12}></Column>
        </Row>
      </Column>

      <Column md={6} lg={4}>
        <ReservationOrders
          reservation_id={reservationId}
          canOrder={!reservation.is_closed}
        />
        {
          !isEmpty(userPayments) && (
            <div style={{ maxWidth: '450px', margin: '0 auto 6rem', padding: '0 0.5rem' }}>
              {
                userPayments.discount_percentage && (
                  <>
                    <div className='d-flex justify-content-between'>
                      <p> {userPayments.discount_percentage}% check discount: </p>
                      <p> &#8722; ${userPayments.discount_amount_sum} </p>
                    </div>
                    <div className='d-flex justify-content-between'>
                      <p> Pre-discount subtotal: </p>
                      <p> ${userPayments.sub_total_sum} </p>
                    </div>
                    <div className='d-flex justify-content-between'>
                      <p> Discount total: </p>
                      <p> &#8722; ${userPayments.discount_amount_sum} </p>
                    </div>
                  </>
                )
              }

              <div className='d-flex justify-content-between'>
                <p> Subtotal: </p>
                {userPayments.discount_percentage
                  ? <p> ${(userPayments.sub_total_sum - parseFloat((userPayments.sub_total_sum * userPayments.discount_percentage / 100).toFixed(2))).toFixed(2)} </p>
                  : <p> ${userPayments.sub_total_sum} </p>
                }
              </div>

              <div className='d-flex justify-content-between'>
                <p> Platform charge: </p>
                <p> ${userPayments.platform_fee_sum} </p>
              </div>

              {reservation.reservation_is_tip_enabled && (
                <div className='d-flex justify-content-between'>
                  <p> Tip </p>
                  <p> ${userPayments.tip_sum} </p>
                </div>
              )}

              <div className='d-flex justify-content-between'>
                <p> Tax: </p>
                <p> ${userPayments.tax_sum} </p>
              </div>

              <div className='d-flex justify-content-between'>
                <p><b> Total: </b></p>
                <p><b> ${userPayments.total_sum} </b></p>
              </div>
            </div>
          )
        }
      </Column>
    </Row>
  )
}

function PastReservationButtons({ onRebookClick, onReceiptClick, showReceiptButton, receiptURL, reservationDate, restaurantName, isWalkIn, showBookingFLow }) {
  return (
    <>
      <div className="actions-btns-mask" />
      <div
        className="restaurant-actions-container"
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center'
        }}
      >
        {showBookingFLow ?
          !isWalkIn &&
          <button
            style={{ marginRight: '14px' }}
            className="btn btn-primary big-round-btn"
            onClick={onRebookClick}
          >
            <span>Re-book</span>
          </button> :
          null
        }
        {showReceiptButton && (
          <button
            className="btn btn-primary big-round-btn"
            disabled={!receiptURL}
          >
            {receiptURL ? <a href={receiptURL} download={`Voila-${restaurantName}-${reservationDate}.pdf`}>Receipt</a> : <span>Loading</span>}
          </button>
        )}
      </div>
    </>
  )
}

export default ReservationPage
