import axios from 'axios'
import { get_restaurants_api, get_restaurant_api, get_code_list_api } from '../../config/api_endpoints'
import { SET_RESTAURANTS, CLEAR_RESTAURANT_RESERVATION, SET_RESTAURANT, SET_CODE_LIST_VALUES } from '../../config/actionTypes'
import { setErrors } from './errorsActions'
import { setLoader } from "./feedbackActions"
import { home_path } from '../../config/pages_paths'

export const getRestaurants = () => async dispatch => {
  try {
    dispatch(setLoader(true))
    const result = await axios.get(get_restaurants_api)
    dispatch(setRestaurants(result.data.restaurants))
    dispatch(setLoader(false))
  } catch (err) {
    dispatch(setLoader(false))
    setErrors(err, () => getRestaurants())
  }
}

export const getRestaurant = (restaurantId, history) => async dispatch => {
  try {
    const result = await axios.get(get_restaurant_api.replace(':restaurantId', restaurantId))
    dispatch(setRestaurant(result.data.restaurant))
  }
  catch (err) {
    history && history.push(home_path)
    dispatch(setErrors(err, () => getRestaurant(restaurantId, history)))
  }
}

export const setRestaurants = payload => dispatch => {
  dispatch({ type: SET_RESTAURANTS, payload })
}

export const setRestaurant = payload => dispatch => {
  dispatch({type: SET_RESTAURANT, payload})
}

export const clearRestaurantReservation = payload => dispatch => {
  dispatch({ type: CLEAR_RESTAURANT_RESERVATION, payload })
}

export const getCodeListValues = () => async dispatch => {
  try {
    const { data } = await axios.get(get_code_list_api)
    if(data) {
      dispatch(setCodeListValues(data))
    }
  } catch (err) {
    dispatch(setErrors(err))
  }
}

export const setCodeListValues = payload => dispatch => {
  dispatch({type: SET_CODE_LIST_VALUES, payload})
}