import React, { useEffect, useState } from 'react'
import classnames from 'classnames'
import InputField from '../common/InputField'
import YouSureItemModal from '../orders/YouSureItemModal'
import errorMessages from '../../components/messages/errorMessages'
import { useDispatch } from 'react-redux'
import { setSwitchToInputComponent } from '../../pages/book/services/actions'

function ReservationTableNumber({ label, info, style, className, reservation, cb, showInputField, hasOpenTableReservationId }) {
    const [tableNumber, setTableNumber] = useState('')
    const [errorMessage, setErrorMessage] = useState('')
    const [onConfirmationModal, setOnConfirmationModal] = useState(false)

    const handleTableNumberChange = e => {
        +e.target.value < 0
            ? setErrorMessage('Please enter a valid table number')
            : (() => {
                setTableNumber(e.target.value)
                setErrorMessage('')
            })()
    }


    const handleActiveReservation = (tableNumber) => {
        cb(tableNumber)
    }

    const handleConfirmationModal = () => {
        setOnConfirmationModal(prevState => !prevState)
    }

    return (
        <>
            {true ?

                    (
                        <div
                            style={style}
                            className={classnames('reservation-info-container', className)}
                        >
                            <div style={{ display: "flex", flexDirection: "column", width: "100%", gap: "1rem" }}>
                                {!hasOpenTableReservationId || showInputField ?
                                    <div>
                                        <div className="booking-restaurant-heading">Please enter your table number instead of scanning the QR Code</div>
                                        <InputField
                                            // style={{maxWidth:"16rem"}}
                                            formGroup={false}
                                            type='text'
                                            onChange={handleTableNumberChange}
                                            value={tableNumber}
                                            min={1}
                                            error={errorMessage}
                                            errorClassName='text-left'
                                            inputClassName='table-number'
                                            placeholder='Input the table number'
                                        />
                                        <div style={{ margin: "1.5rem auto auto" }}>
                                            <button
                                                disabled={!tableNumber}
                                                className='btn btn-primary big-round-btn-2'
                                                onClick={handleConfirmationModal}>
                                                Next
                                            </button>
                                        </div>
                                    </div> :
                                    <div>
                                         <div className="booking-restaurant-heading">Submit your orders now</div>
                                        <button
                                            className='btn btn-primary big-round-btn'
                                            onClick={handleConfirmationModal}>
                                            Next
                                        </button>
                                    </div>
                                }
                            </div>


                        </div>
                    ) :
                
                    <div className="booking-restaurant-heading">
                        {errorMessages.reservationNotEligibleToStartBookedOrder}
                    </div>
                }
            {
                onConfirmationModal ?
                    <YouSureItemModal
                        show={onConfirmationModal}
                        message={showInputField ? 
                            "Has the hostess confirmed the table number you are attempting to use?" :
                            "Proceed with your experience?"
                        }
                        action={() => {
                            handleActiveReservation(tableNumber)
                            handleConfirmationModal()
                        }}
                        close={handleConfirmationModal}
                    /> :
                    null
            }
        </>
    )
}

export default ReservationTableNumber
