import React, { Fragment, useEffect } from 'react'
import Modal from '../modal/Modal'
import { useDispatch, useSelector } from 'react-redux'
import ModalHeading from '../common/ModalHeading'
import SeparationLine from '../common/SeparationLine'
import Column from '../../containers/layout/Column'
import Row from '../../containers/layout/Row'
import {
  acceptInvitation,
  declineInvitation
} from '../../pages/book/services/actions'
import { setActiveModal } from '../../store/actions/feedbackActions'
import { home_path } from '../../config/pages_paths'
import { useHistory } from 'react-router'
import isEmpty from 'lodash.isempty'

function AcceptOptions({ onAccept, onDecline }) {
  return (
    <div
      style={{ width: '100%' }}
      className="text-center"
    >

        <h3 style={{margin:"1rem 0 0"}}>Are you coming?</h3>
      <div className="booking-btns-2 accept-invitation-btn-wrapper mt-4">
        <button onClick={onDecline} className="btn decline-invitation-btn">
          <span>No</span>
        </button>
        <button onClick={onAccept} className="btn accept-invitation-btn">
          <span>Yes</span>
        </button>
      </div>
    </div>
  )
}

function ReservationRestaurantItem({
  image,
  name,
  address,
  date = '',
  sizeOfParty
}) {
  return (
    <Fragment>
      <div className="reservation-restaurant-item">
        <div className="image-section">
          <img src={image} alt="restaurant" />
        </div>
        <div className="details-section">
          <h2>{name}</h2>
          <span>{address}</span>
        </div>
      </div>
      <div className="invite-reservation-details">
        <span>Reservation:</span>{' '}
        <span>
          <strong>
            {date.replace(/-/g, '/')} | {sizeOfParty} {+sizeOfParty !== 1 ? 'people' : 'person'}{' '}
          </strong>
        </span>
      </div>
    </Fragment>
  )
}

function AcceptInvitationModal({ show, close }) {
  const {
    booking: { invitationDetails, invitationToken, },
    user: { user }
  } = useSelector(state => state)
  const dispatch = useDispatch()
  const history = useHistory()

  const onAcceptInvitationHandler = () => {
    dispatch(acceptInvitation(invitationToken))
  }

  const onDeclineInvitationHandler = () => {
    dispatch(declineInvitation(invitationToken))
    dispatch(setActiveModal(''))
    history.push(home_path)
  }

  const onCloseHandler = () => {
    history.push(home_path)
    close()
  }

  useEffect(() => {
    if (!isEmpty(invitationDetails))
      if (
        invitationDetails.reservation.cancelled ||
        invitationDetails.reservation.user_id === user.user_id
      ) {
        dispatch(setActiveModal(''))
        history.push(home_path)
      }
  }, [invitationDetails, history, dispatch, user])

  return (
    <Modal className="" show={show} close={onCloseHandler}>
      {/* <ModalHeading title="Invitation for dinning" onClick={onCloseHandler} /> */}
      <h3 style={{display:"flex", justifyContent:"center"}}>Invitation for dinning</h3>
      <div className="invitation-content-wrapper">
        <Row>
          <Column className="text-center invitation-header" col={12}>
            <h5>{invitationDetails.host}</h5>
        
            <p>is inviting you for wining and dining at:</p>
          </Column>
          {/* <SeparationLine /> */}
        </Row>
        <ReservationRestaurantItem
          name={(invitationDetails.reservation || {}).name}
          address={(invitationDetails.reservation || {}).address}
          image={(invitationDetails.reservation || {}).restaurant_image}
          date={(invitationDetails.reservation || {}).date_string}
          sizeOfParty={(invitationDetails.reservation || {}).size_of_party}
        />
        <SeparationLine width= '100%' />
      </div>
      <AcceptOptions
        onAccept={onAcceptInvitationHandler}
        onDecline={onDeclineInvitationHandler}
      />
    </Modal>
  )
}

export default AcceptInvitationModal
