import { Autocomplete, Box, Checkbox, Chip, FormControl, InputLabel, ListItemText, MenuItem, Popover, Popper, Select, SwipeableDrawer, TextField, ThemeProvider, createTheme } from "@mui/material";
import { makeStyles } from '@mui/styles';
import { darken, lighten, styled } from '@mui/system';
import React from 'react';


export const CustomDrawer = ({
    id,
    open,
    toggleDrawer,
    className,
    content,
    paperClasses,
    PaperProps,
    paperWidth,
    anchor,
}) => {
    return (
        <SwipeableDrawer
            id={id}
            anchor={anchor}
            open={open}
            onOpen={toggleDrawer}
            onClose={toggleDrawer}
            classes={{ paper: paperClasses }}
            className={className}
            PaperProps={{
                sx: {
                    width: paperWidth
                }
            }}
        >
            {content}
        </SwipeableDrawer>
    );
};

export const CustomPopover = ({
    id,
    paperClasses,
    open,
    togglePopover,
    content,
    anchorEl,
    anchorOrigin,
    transformOrigin,
    popoverEnter,
    popoverLeave,
    popoverAnchor,
    disableRestoreFocus,
    sx,
}) => {
    return (
        <Popover
            id={id}
            classes={{ paper: paperClasses }}
            open={open}
            onClose={togglePopover}
            anchorEl={anchorEl}
            anchorOrigin={anchorOrigin}
            transformOrigin={transformOrigin}
            sx={sx}
            disableRestoreFocus={disableRestoreFocus}
            >
            {content}
        </Popover>
    )
}

export const CustomSelect = ({
    variant,
    id,
    name,
    sx,
    initialWidth,
    autoFocus,
    autoComplete,
    className,
    menuItems = [],
    label,
    labelId,
    onChange,
    value,
    required,
    multiple,
    renderValue,
    onMenuItemsClick,
    color,
    menuItemsName,
    menuItemsValue,
    isChip
}) => {
    const useStyles = makeStyles((theme) => ({
        select: {
            position: "relative"
        },
        InputLabel: {
            top: "-12%",
            left: "2%",
            color: "#8292a2",
            transform: "translate(0, 1.5px) scale(0.75)"
        },
    }))

    const classes = useStyles()

    return (
        <FormControl
            className={classes.select}
            fullWidth={!initialWidth ? true : false}
        >

            <InputLabel
                required={required}
                id={labelId}
            // classes={classes.InputLabel}
            >
                {label}
            </InputLabel>

            <Select
                fullWidth
                variant={variant ? variant : "outlined"}
                id={id}
                required={required}
                multiple={multiple}
                name={name}
                autoFocus={autoFocus}
                autoComplete={autoComplete}
                sx={sx ? sx : { height: "56px", }}
                className={className}
                labelId={labelId}
                value={value}
                color={color}
                onChange={onChange}
                label={label}
                renderValue={renderValue ?

                    ((selected) => !isChip ?
                        Array.isArray(selected) ? selected.join(', ') :
                            selected :
                        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                            {selected.map((value) => (
                                <Chip key={value} label={value} />
                            ))}
                        </Box>) :
                    null}
                MenuProps={{
                    // getContentAnchorEl: () => null,
                    disableScrollLock: true
                }}
            >

                {/* Map the array provided for the select */}

                {menuItems?.map((item, idx) => {
                    if (multiple) {
                        if (typeof item === 'object' && menuItemsName) {
                            return (
                                <MenuItem
                                    key={item?.id ?? idx}
                                    value={menuItemsValue ? item[menuItemsValue] : item[menuItemsName]}>
                                    <Checkbox
                                        onClick={onMenuItemsClick}
                                        checked={value.indexOf(item[menuItemsName]) > -1}
                                    />
                                    <ListItemText
                                        primary={item[menuItemsName]} />
                                </MenuItem>
                            )
                        }
                        else {
                            return (
                                <MenuItem
                                    key={idx}
                                    value={item}>
                                    <Checkbox
                                        onClick={onMenuItemsClick}
                                        checked={value.indexOf(item) > -1}
                                    />
                                    <ListItemText primary={item} />
                                </MenuItem>
                            );
                        }
                    } else if (typeof item === 'string' || typeof item === 'number' || typeof item === 'boolean') {
                        return (
                            <MenuItem key={idx} value={item}>
                                <ListItemText primary={item.toString()} />
                            </MenuItem>
                        );
                    } else if (typeof item === 'object' && menuItemsName) {
                        return (
                            <MenuItem
                                key={item?.name || idx}
                                value={item[menuItemsValue] ? item[menuItemsValue] : item[menuItemsName]}>
                                <ListItemText
                                    primary={item[menuItemsName]} />
                            </MenuItem>
                        );
                    } else {
                        return null;
                    }
                })}

            </Select>
        </FormControl >
    )
}

const GroupHeader = styled('div')(({ theme }) => ({
    position: 'sticky',
    top: '-8px',
    padding: '4px 10px',
    color: theme.palette.primary.main,
    backgroundColor:
        theme.palette.mode === 'light'
            ? lighten(theme.palette.primary.light, 0.85)
            : darken(theme.palette.primary.main, 0.8),
}));

const GroupItems = styled('ul')({
    padding: 0,
});
export function CustomAutocomplete({
    id,
    open,
    onToggle,
    menuItems,
    onChange,
    multiple,
    className,
    label,
    sx,
    popupIcon,
    disableCloseOnSelect,
    handleIsAutocompeltePopoverOnClose,
    handleIsAutocompeltePopoverOnOpen,
    mainColor,
    secondaryColor,
    closeIcon,
    fontFamily,
}) {
    const options = menuItems.flatMap((item) => {
        return item.data.map((option) => ({
            section: item.label,
            option: option,
        }));
    });

    // Define a custom equality test function
    const isOptionEqualToValue = (option, value) => {
        return option.option.id === value.option.id && option.option.name === value.option.name;
    };

    // const useStyles = makeStyles((theme) => ({
    //     paper: {
    //         width: "24rem!important",
    //         transform: "translate3d(16px, 142.667px, 0px)"
    //     }
    // }))

    // const classes = useStyles()

    const theme = createTheme({
        components: {
            MuiOutlinedInput: {
                styleOverrides: {
                    root: {
                        "&:hover .MuiOutlinedInput-notchedOutline": {
                            borderColor: `${mainColor}!important`,
                        },
                        "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                            borderColor: `${mainColor}!important`,
                        },
                        "&.Mui-focused .MuiOutlinedInput-notchedOutline:hover": {
                            borderColor: `${mainColor}!important`,
                        },
                    },
                },
            },
            MuiInputLabel: {
                styleOverrides: {
                    root: {
                        "&.Mui-focused": {
                            color: mainColor,
                        },
                    },
                },
            },
            MuiAutocomplete: {
                styleOverrides: {
                    root: {
                        '.MuiAutocomplete-endAdornment': {
                            top: "calc(50% - 18px)!important",
                        },
                        '& .MuiAutocomplete-clearIndicator': {
                            color: mainColor,
                            margin: "0 0 0.25rem 0",
                        },
                    },
                    popper: {
                        zIndex: "1101!important"
                    }
                },
            },
        },
    });

    return (
        <ThemeProvider theme={theme}>

            <Autocomplete
                id={id}
                options={options}
                groupBy={(option) => option.section}
                getOptionLabel={(option) => option.option.name}
                isOptionEqualToValue={isOptionEqualToValue}
                multiple={multiple}
                onChange={onChange}
                open={open}
                // open={true}
                popupIcon={popupIcon}
                // classes={classes.paper}
                onOpen={handleIsAutocompeltePopoverOnOpen}
                onClose={handleIsAutocompeltePopoverOnClose}
                sx={sx}
                fullWidth
                // componentsProps={{ popper: { style: { width: "93%!important" } } }}
                className={className}
                disableCloseOnSelect={disableCloseOnSelect}
                renderTags={(value, getTagProps) =>
                    value.map((option, index) => (
                        <Chip
                            key={index}
                            // className={chipClass}
                            sx={{ backgroundColor: mainColor, color: secondaryColor }}
                            variant="filled"
                            deleteIcon={closeIcon}
                            label={`${option.option.name}`}
                            {...getTagProps({ index })}
                        />
                    ))
                }

                renderInput={(params) =>
                    <TextField {...params} label={label} />
                }
                // PopperComponent={(props) => <Popper style={{transform:'translate3d(1400px, 200.5px, 0px)!important'}} {...props} placement="bottom-start" />}
                renderGroup={(params) => (
                    <li key={params.key}>
                        <GroupHeader
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                backgroundColor: mainColor,
                                color: secondaryColor,
                                borderRadius: '0.5rem',
                                fontFamily: fontFamily,
                                fontSize: '1.4rem',
                            }}
                        >
                            {menuItems.map(
                                (menuItem) =>
                                    menuItem.label === params.group && (
                                        <div
                                            key={menuItem.label}
                                            style={{
                                                margin: '0 0.25rem 0.35rem 0', // Adjust the margin as needed
                                            }}
                                        >
                                            {menuItem.icon}
                                        </div>
                                    )
                            )}
                            {params.group}
                        </GroupHeader>
                        <GroupItems
                            sx={{
                                fontFamily: fontFamily,
                                fontSize: '1.25rem',
                            }}
                        >
                            {params.children}
                        </GroupItems>
                    </li>
                )}
            />
        </ThemeProvider>
    );
}
